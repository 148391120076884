import { useSearchQuery } from '@xti/frontend-kit-components'

export const useLoginNotAuthorizedSearchQueries = () => {
  const searchQuery = useSearchQuery()

  return {
    content: searchQuery?.content || '',
  }
}

const useCustom = () => {
  const search = useLoginNotAuthorizedSearchQueries()

  return {
    content: search.content,
  }
}

export default useCustom
