import { makeStyles } from '@material-ui/styles'
import { black } from '@xti/frontend-kit-components/colors'

import { TABLET } from '~/config/screen'

const style = makeStyles((theme) => ({
  container: {
    [`@media ${TABLET.MAX_WIDTH}`]: {
      height: 'auto',
      width: '100%',
    },
  },
  logo: {
    width: '140px',
    marginBottom: theme.spacing(5),
  },
  formWrapper: {
    height: 'auto',
    width: '75%',
  },
  background: {
    background: "url('/public/images/background.png')",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '15px',
    margin: theme.spacing(3),
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover $button': {
      borderColor: 'transparent',
      background: theme.palette.primary.light,
    },
    textDecoration: 'none',
    textAlign: 'center',
  },
  disabledLink: {
    pointerEvents: 'none',
  },
  button: {
    borderWidth: '1.5px',
    borderStyle: 'solid',
    borderRadius: '10px',
    borderColor: black[400],
    color: theme.palette.text.secondary,
    textTransform: 'none',
    maxHeight: '112px',
    minHeight: '112px',
    maxWidth: '112px',
    minWidth: '112px',
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    '&:disabled': {
      background: black[200],
      border: '1.5px solid transparent',
    },
  },
}))

export default style
