import { useCallback } from 'react'

const useCustom = () => {
  const handlePath = useCallback(
    (url) => () => {
      window.location = url
    },
    [],
  )

  return {
    handlePath,
  }
}

export default useCustom
