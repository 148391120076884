import { makeStyles } from '@material-ui/styles'

import { DRAWER_WIDTH, TABLET } from '~/config/screen'

const style = makeStyles((theme) => ({
  container: {
    [`@media ${TABLET.MAX_WIDTH}`]: {
      height: 'auto',
      width: '100%',
    },
  },
  contentHeader: {
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${DRAWER_WIDTH}`,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

export default style
