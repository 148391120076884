import React, { Suspense } from 'react'
import { Switch, Redirect, Route, withRouter } from 'react-router-dom'

import { Loading } from '@xti/frontend-kit-components'

import ForgetPasswordPage from '~/pages/ForgetPassword'
import LoginPage from '~/pages/Login'
import LoginNotAuthorized from '~/pages/LoginNotAuthorized'
import NewPasswordPage from '~/pages/NewPassword'
import SSOLoginPage from '~/pages/SSOLogin'

const PublicLayout = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route exact path="/" component={LoginPage} />
      <Route exact path="/forget-password" component={ForgetPasswordPage} />
      <Route exact path="/new-password" component={NewPasswordPage} />
      <Route exact path="/sso/login" component={SSOLoginPage} />
      <Route exact path="/not-authorized" component={LoginNotAuthorized} />
      <Redirect to="/" />
    </Switch>
  </Suspense>
)

export default withRouter(PublicLayout)
