import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { Box } from '@material-ui/core'

import { logError } from '~/utils/error-logger'

// TODO: Refactor to functional component
// Change to functional component if componentDidCatch available as react hooks

class InternalServerError extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      lastLocation: null,
      hasError: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.lastLocation) {
      return {
        hasError: false,
      }
    }

    if (state.hasError) {
      return {
        hasError: true,
      }
    }

    return state
  }

  componentDidCatch(err, errInfo) {
    const { location } = this.props

    this.setState({
      lastLocation: location.pathname,
      hasError: err,
    })

    logError({ info: errInfo, exception: err })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <img
            alt="internal-server-error"
            src="/public/images/server-error.svg"
          />
        </Box>
      )
    }

    return children
  }
}

InternalServerError.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default withRouter(InternalServerError)
