import { useCallback, useState, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { MENU_NAME } from './helper'

const useCustom = ({ location }) => {
  const history = useHistory()
  const [pathName, setPathName] = useState('')

  const isLandingPage = useMemo(() => location.pathname === '/', [location])
  const menu = useMemo(() => MENU_NAME?.[location.pathname.substring(1)], [
    location,
  ])

  const handleRoute = useCallback(
    (value = '') => () => {
      history.push({
        pathname: value,
      })
    },
    [],
  )

  useEffect(() => {
    if (isLandingPage) {
      setPathName('My Account')
    } else {
      setPathName(menu?.name)
    }
  }, [isLandingPage, menu])

  return {
    handler: {
      handleRoute,
    },
    state: {
      pathName,
    },
    data: {
      menu,
    },
  }
}

export default useCustom
