import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useLazyQuery } from '@apollo/react-hooks'
import { useAlert } from '@xti/frontend-kit-components'
import { sortBy } from 'lodash-es'

import { ALERT_SIZE_MAPPING } from '~/config/screen'
import LIST_OF_SSO_LINK from '~/queries/auth/listSSOLink'
import useAuth, { authLogin } from '~/utils/auth'
import { graphqlDefaultOptions } from '~/utils/graphql'

const useCustom = ({ screen }) => {
  const { handleLogin } = useAuth()
  const { handleOpenSnackbar } = useAlert()
  const history = useHistory()

  const [loadSSOLink, { data: listSSOLink }] = useLazyQuery(LIST_OF_SSO_LINK, {
    ...graphqlDefaultOptions,
  })

  const ssoLinks = useMemo(() => {
    if (listSSOLink?.auth?.listSSOLink?.links.length > 0) {
      const ssoDatas = listSSOLink?.auth?.listSSOLink?.links.map(
        ({ name, link }) => {
          const parsedLink = new URL(link)
          const { searchParams } = parsedLink
          searchParams.set(
            'state',
            `${searchParams.get('state')}~${window.location.origin}`,
          )
          return {
            name,
            link: parsedLink.toString(),
          }
        },
      )
      return sortBy(ssoDatas, ['name'])
    }
    return []
  }, [listSSOLink])

  const handleSubmit = useCallback(async (values) => {
    const data = await authLogin(values.username, values.password)

    const token = data?.data?.token?.requestToken?.token
    const refreshToken = data?.data?.token?.requestToken?.refreshToken
    let responseMessage = data?.data?.token?.requestToken?.message

    if (data?.code === 500) {
      responseMessage = data?.detail
    }
    if (token) {
      await handleLogin(token, refreshToken)
    } else {
      handleOpenSnackbar({
        size: ALERT_SIZE_MAPPING?.[screen],
        testid: 'fail-login-alert',
        title: `Login failed: ${
          responseMessage || 'Invalid username or password.'
        }`,
        variant: 'error',
      })
    }
  }, [])

  const handleForget = useCallback(() => {
    history.push({
      pathname: '/forget-password',
    })
  }, [])

  useEffect(() => {
    loadSSOLink()
  }, [])

  return {
    data: {
      ssoLinks,
    },
    handler: {
      handleSubmit,
      handleForget,
    },
  }
}

export default useCustom
