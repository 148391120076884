import gql from 'graphql-tag'

const query = gql`
  subscription sub_message {
    inapp {
      getMessage(
        param: { date: "", product: "", limit: 1, page: 1, subject: "", id: "" }
      ) {
        pageInfo {
          totalUnread
        }
        messages {
          id
          product
          sender
          subject
          content
          read
          createdby
          createdat
        }
      }
    }
  }
`

export default query
