import React from 'react'

import { useMediaQuery } from '@material-ui/core'
import { NotAuthorized } from '@xti/frontend-kit-components'

import { MOBILE } from '~/config/screen'

import useCustom from './hooks'

const LoginNotAuthorized = () => {
  const matches = useMediaQuery(MOBILE.MAX_WIDTH)
  const { content } = useCustom()
  return (
    <NotAuthorized
      buttonLink="/"
      buttonSize={matches ? 'md' : 'lg'}
      buttonText="Try logging in again"
      content={content}
      imageSize="313.11px"
      title="Hmm... We're having trouble logging you in."
    />
  )
}

export default LoginNotAuthorized
