import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { fontSize, spacing } from '@xti/frontend-kit-components'
import { black, palette } from '@xti/frontend-kit-components/colors'

export { fontSize }

// http://chir.ag/projects/name-that-color/
// https://colornamer.robertcooper.me/
export const colors = {
  Mantis: '#73BE66',
  Feta: '#E7FBE4',
  Gamboge: '#EE8F0E',
  PeachCream: '#FFF1DF',
  Rosewood: '#660002',
  Concrete: '#F2F2F2',
  CodGray: '#121212',
  Pippin: '#FFE0E0',
  Mojo: '#C53F3F',
  Alto: '#DDDDDD',
  HavelockBlue: '#419BD8',
  DustyGray: '#9B9B9B',
  DodgerBlue2: '#1890FF',
  Mercury: '#e9e9e9',
  FrenchGray: '#B9BEC8',
  WildSand: '#f5f5f5',
  White: '#FFFFFF',
}

const theme = createMuiTheme({
  overrides: {
    MuiSelect: {
      select: {
        maxWidth: '7.813vw',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: `${colors.HavelockBlue} !important`,
        '&$checked': {
          color: `${colors.HavelockBlue} !important`,
        },
      },
    },
  },
  palette: {
    common: {
      black: palette.Black,
      white: palette.White,
    },
    primary: {
      light: palette.MintTulip,
      main: colors.DodgerBlue2,
      dark: palette.HavelockBlue,
    },
    secondary: {
      light: palette.SecondaryLight,
      main: colors.DodgerBlue2,
      dark: palette.SecondaryDark,
    },
    error: {
      light: palette.ErrorLight,
      main: palette.Error,
      dark: palette.ErrorDark,
    },
    success: {
      light: palette.SuccessLight,
      main: palette.Success,
      dark: palette.SuccessDark,
    },
    text: {
      primary: black[500], // #5B5B5B
      secondary: palette.Black,
      disabled: black[400], // #9B9B9B
      hint: black[300], // #C4C4C4
    },
    background: {
      default: black[100], // #F8F8F8
    },
    divider: black[300], // #C4C4C4
    action: {
      disabled: black[400], // #9B9B9B
      disabledBackground: black[200], // #E5E5E5
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    fontFamily: 'Poppins, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  element: {
    boxShadow: '0 1px 2px 0 rgba(128, 128, 128, 0.5)',
  },
  // ? spacing list
  // '0.125rem', // 2px // 0
  // '0.25rem', // 4px // 1
  // '0.5rem', // 8px // 2
  // '0.75rem', // 12px // 3
  // '1rem', // 16px // 4
  // '1.25rem', // 20px // 5
  // '1.5rem', // 24px // 6
  // '1.75rem', // 28px // 7
  // '2rem', // 32px // 8
  // '2.25rem', // 36px // 9
  // '2.5rem', // 40px // 10
  // '2.75rem', // 44px // 11
  // '3rem', // 48px // 12
  spacing: (factor) => spacing[factor],
})

export default responsiveFontSizes(theme)
