import PropTypes from 'prop-types'
import React from 'react'

import { Avatar } from '@material-ui/core'
import { Text, generalSize } from '@xti/frontend-kit-components'
import { palette } from '@xti/frontend-kit-components/colors'
import tinycolor from 'tinycolor2'

import { getRandomColor } from '~/utils/colors'
import { getInitials } from '~/utils/string'

import useStyle from './style'

const AvatarBadge = ({ bold, className, color, fontSize, name }) => {
  const avatarColor = tinycolor(palette[color])
  const contrastText = avatarColor.isLight()
  const classes = useStyle({ color, contrastText })

  return (
    <Avatar className={`${classes.avatar} ${className}`}>
      <Text size={fontSize} variant="paragraph" bold={bold}>
        {getInitials(name)}
      </Text>
    </Avatar>
  )
}

AvatarBadge.defaultProps = {
  bold: false,
  className: '',
  color: getRandomColor(),
  fontSize: 'xs',
  name: '',
}

AvatarBadge.propTypes = {
  bold: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf([...Object.keys(palette), 'inherit']),
  fontSize: PropTypes.oneOf(Object.keys(generalSize)),
  name: PropTypes.string,
}

export default AvatarBadge
