import { PRODUCT, PRODUCT_ALT } from '~/config/constants'

export const getProductData = (userProd = []) => {
  const res = []
  if (userProd.length > 0) {
    PRODUCT.forEach((data) => {
      const selectedProduct = userProd.find(
        (product) => product?.prodID === data,
      )
      if (selectedProduct) {
        res.push({
          id: selectedProduct?.prodID,
          name: selectedProduct?.prodName,
          img: `${PRODUCT_ALT[selectedProduct?.prodID]}.png`,
          path: selectedProduct?.prodPath,
        })
      }
    })
  }
  return res
}

export default [getProductData]
