import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { Text } from '@xti/frontend-kit-components'

import HeaderPopoverMobile from '../HeaderPopoverMobile'

import useCustom from './hooks'
import useStyle from './style'

const HeaderMobile = ({ location }) => {
  const { data, state, handler } = useCustom({ location })
  const classes = useStyle({ expandHeader: data.menu?.expandHeader })

  return (
    <AppBar position="static" className={classes.appbar} elevation={0}>
      <Toolbar classes={{ root: classes.toolbarRootOverrides }}>
        <Box display="flex" flex={1} color="white">
          <Box display="flex" alignItems="center" ml={4} mr={2} flex={1}>
            <HeaderPopoverMobile />
            {state.pathName && (
              <Box ml={5}>
                <Text component="div" size="sm" variant="paragraph" bold>
                  {state.pathName}
                </Text>
              </Box>
            )}
            {data.menu?.closePath && (
              <Box display="flex" flex={1} justifyContent="flex-end">
                <IconButton
                  size="small"
                  onClick={handler.handleRoute(data.menu.closePath)}
                >
                  <CloseIcon htmlColor="white" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

HeaderMobile.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default withRouter(HeaderMobile)
