import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  ADMINISTRATOR_MENUS,
  AUDIT_LOG_MENUS,
  DRAWER_MENU_KEYS,
  MENUS,
  ROLE_KEYS,
  USER_MENUS,
} from '~/config/constants'
import routes from '~/routes'
import useAuth from '~/utils/auth'

const useCustom = () => {
  const { checkPathPermission, getUserInformation, setMenuType } = useAuth()
  const history = useHistory()
  const location = useLocation()

  const [isLandingPage, setIsLandingPage] = useState(false)

  const user = getUserInformation()

  const routesWithHeader = useMemo(
    () => routes.filter((route) => route.withHeader).map((route) => route.path),
    [],
  )

  useEffect(() => {
    setIsLandingPage(false)
    if (location.pathname !== '/') {
      const destinationPath = location.pathname.substring(1)
      if (
        !destinationPath.includes('not-found') &&
        !destinationPath.includes('not-authorized')
      ) {
        const existingRoutes = MENUS.includes(destinationPath)
        const allowed = checkPathPermission(destinationPath)
        if (ADMINISTRATOR_MENUS.includes(destinationPath)) {
          setMenuType(DRAWER_MENU_KEYS.ADMINISTRATOR)
        }
        if (USER_MENUS.includes(destinationPath)) {
          setMenuType(DRAWER_MENU_KEYS.USER)
        }
        if (AUDIT_LOG_MENUS.includes(destinationPath)) {
          setMenuType(DRAWER_MENU_KEYS.AUDIT_LOG)
        }
        if (!existingRoutes) {
          history.replace({
            pathname: '/not-found',
          })
          return
        }
        if (!allowed) {
          history.replace({
            pathname: '/not-authorized',
          })
        }
      }
    } else {
      setIsLandingPage(true)
    }
  }, [location.pathname])

  // ? must change password feature
  useEffect(() => {
    if (
      user?.mustChangePwd &&
      user?.userAccess?.roleType !== ROLE_KEYS.SYS_ADMIN
    ) {
      history.push('/settings/password')
    }
  }, [user, location.pathname])

  return {
    data: {
      routesWithHeader,
      user,
    },
    state: {
      isLandingPage,
    },
  }
}

export default useCustom
