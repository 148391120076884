import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core'
import { Button, Loading, Text } from '@xti/frontend-kit-components'

import ProtectedLayout from '~/components/Layout/Protected'
import PublicLayout from '~/components/Layout/Public'
import { MOBILE } from '~/config/screen'

import useCustom from './hooks'

const IndexPage = () => {
  const { data, handler, state } = useCustom()
  const matches = useMediaQuery(MOBILE.MAX_WIDTH)

  const renderPage = () => {
    if (state.isLoggedIn) {
      if (data?.profileInfo?.userAccess?.permission?.length > 0) {
        return <ProtectedLayout isMobile={matches} />
      }
      return <Loading />
    }
    return <PublicLayout />
  }

  return (
    <>
      {renderPage()}
      <Dialog
        open={state.isDialogOpen}
        aria-labelledby="update-app-dialog-title"
        aria-describedby="update-app-dialog-description"
      >
        <DialogTitle>
          <Text component="div" size="md" variant="paragraph" bold>
            Update Application
          </Text>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="update-app-dialog-description">
            <Text component="div" size="sm" variant="paragraph">
              There is a new version available. Do you want to reload page to
              update?
            </Text>
            <Text component="div" size="sm" variant="paragraph">
              If you choose &quot;Update Later&quot;, the application will
              automatically update if you close the window.
            </Text>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            handleClick={handler.handleCloseDialog}
            text="Update Later"
            size="md"
            variant="secondary"
          />
          <Button
            handleClick={handler.handleUpdateApp}
            text="Update Now"
            size="md"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default IndexPage
