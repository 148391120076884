import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Button, Grid, Link as MaterialLink } from '@material-ui/core'
import { Text } from '@xti/frontend-kit-components'
import { black } from '@xti/frontend-kit-components/colors'
import classNames from 'classnames'

import useCustom from './hooks'
import useStyle from './style'

const ChooseDashboard = () => {
  const { data } = useCustom()
  const classes = useStyle()

  return (
    <Box display="flex" height="100%" flex={1} className={classes.container}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={1}
        ml={8}
      >
        <Box
          display="flex"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          className={classes.formWrapper}
        >
          <img
            className={classes.logo}
            src="/public/images/xapiens-logo-black.svg"
            alt="landing"
          />
          {data.productDatas.length > 0 && (
            <>
              <Box color={black[400]}>
                <Text size="sm" variant="paragraph" bold>
                  Switch to
                </Text>
              </Box>
              <Box mt={5} mb={7} color="black">
                <Grid container spacing={5}>
                  {data.productDatas.map((product) => (
                    <Grid item xs={4} xl={3} key={product?.productID}>
                      <MaterialLink
                        href={product?.prodPath}
                        className={classes.link}
                        key={`button-${product.prodAltName}-${product?.productID}`}
                        underline="none"
                        color="inherit"
                      >
                        <Box mb={4}>
                          <Button className={classes.button}>
                            <img
                              alt={product.prodAltName}
                              src={`/public/images/products/${product?.prodImage}`}
                            />
                          </Button>
                        </Box>
                        <Text size="sm" variant="paragraph" bold>
                          {product?.prodName}
                        </Text>
                      </MaterialLink>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
          <Box color={black[400]} mt={7}>
            <Text size="sm" variant="paragraph" bold>
              Helpful Links
            </Text>
          </Box>
          <Box mt={5}>
            <Grid container spacing={5}>
              {data.helpfulLinkDatas.map((helpfulLink) => (
                <Grid item xs={4} xl={3} key={helpfulLink.id}>
                  <RouterLink
                    to={helpfulLink.path}
                    className={classNames(classes.link, {
                      [classes.disabledLink]: !helpfulLink.path,
                    })}
                    key={`button-${helpfulLink.alt}`}
                  >
                    <Box mb={4}>
                      <Button
                        className={classes.button}
                        disabled={!helpfulLink.path}
                      >
                        <img
                          alt={helpfulLink.alt}
                          src={`/public/images/${helpfulLink.img}`}
                        />
                      </Button>
                    </Box>
                    <Box color="black">
                      <Text size="sm" variant="paragraph" bold>
                        {helpfulLink?.name}
                      </Text>
                    </Box>
                  </RouterLink>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={1}
        className={classes.background}
      />
    </Box>
  )
}

export default ChooseDashboard
