import * as Yup from 'yup'

const resetSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must contain at least 8 characters')
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.*[\W_]).{8,}$/,
      'Password must contain 1 lowercase, 1 uppercase, 1 special character, and 1 number',
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Required'),
})

export default resetSchema
