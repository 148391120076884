import React from 'react'

import { Box } from '@material-ui/core'
import { Button } from '@xti/frontend-kit-components'
import { Field, Form, Formik } from 'formik'

import CustomTextField from '~/components/CustomTextField'
import { SCREEN_TYPE } from '~/config/screen'
import useCustom from '~/pages/NewPassword/hooks'
import resetSchema from '~/pages/NewPassword/schema'

import useStyle from './style'

const NewPasswordMobile = () => {
  const { handler } = useCustom({ screen: SCREEN_TYPE.MOBILE })
  const classes = useStyle()

  return (
    <Box
      display="flex"
      height="100%"
      flex={1}
      className={classes.container}
      flexDirection="column"
    >
      <Box display="flex" justifyContent="flex-end" mb={10}>
        <img
          src="/public/images/xapiens-logo-black.svg"
          alt=""
          className={classes.background}
        />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
        <Box
          display="flex"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          className={classes.formWrapper}
          pb={4}
        >
          <img
            className={classes.logo}
            src="/public/images/xapiens-logo-black.svg"
            alt="landing"
          />
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validationSchema={resetSchema}
            onSubmit={handler.handleSubmit}
          >
            {({ errors, isSubmitting }) => (
              <Form>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={classes.formSection}
                >
                  <Field
                    component={CustomTextField}
                    error={!!errors.password}
                    helperText={errors.password}
                    label="New Password"
                    labelPosition="vertical"
                    name="password"
                    placeholder="New Password"
                    size="sm"
                    type="password"
                    width="100%"
                  />
                  <Field
                    component={CustomTextField}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    label="Confirm New Password"
                    labelPosition="vertical"
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                    size="sm"
                    type="password"
                    width="100%"
                  />
                  <Box display="flex" flexDirection="column" mt={8}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      text="Reset Password"
                      size="md"
                      width="100%"
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  )
}

export default NewPasswordMobile
