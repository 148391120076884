import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useLazyQuery } from '@apollo/react-hooks'
import { useSearchQuery } from '@xti/frontend-kit-components'
import { isEmpty } from 'lodash-es'

import LIST_OF_SSO_LINK from '~/queries/auth/listSSOLink'
import useAuth from '~/utils/auth'
import { graphqlDefaultOptions } from '~/utils/graphql'

export const useSSOSearchQueries = () => {
  const searchQuery = useSearchQuery()

  if (
    searchQuery?.t ||
    searchQuery?.r ||
    searchQuery?.m ||
    searchQuery?.provider
  ) {
    return {
      token: searchQuery?.t,
      refreshToken: searchQuery?.r,
      responseMessage: searchQuery?.m,
      provider: searchQuery?.provider,
    }
  }
  return {}
}

const useCustom = () => {
  const { handleLogin } = useAuth()
  const history = useHistory()
  const search = useSSOSearchQueries()

  const [loadSSOLink, { data: listSSOLink }] = useLazyQuery(LIST_OF_SSO_LINK, {
    ...graphqlDefaultOptions,
  })

  const ssoLink = useMemo(
    () =>
      listSSOLink?.auth?.listSSOLink?.links.find(
        (data) => data.name?.toLowerCase() === search?.provider,
      ),
    [listSSOLink],
  )

  useEffect(() => {
    if (isEmpty(search)) {
      history.push('/')
    }
    if (search.responseMessage) {
      if (search.token && search.refreshToken) {
        handleLogin(search.token, search.refreshToken)
        history.push('/')
      } else {
        history.push({
          pathname: '/not-authorized',
          search: `?content=${encodeURIComponent(search.responseMessage)}`,
        })
      }
    }
    if (search.provider) {
      loadSSOLink()
    }
  }, [])

  useEffect(() => {
    if (ssoLink) {
      window.location.assign(ssoLink.link)
    }
  }, [ssoLink])
}

export default useCustom
