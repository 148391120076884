import PropTypes from 'prop-types'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'

import { AppBar, Box, Toolbar } from '@material-ui/core'
import { Text } from '@xti/frontend-kit-components'

import LogoutPopover from '~/components/LogoutPopover'
import NotificationSummary from '~/components/NotificationSummary'
import ProductPopover from '~/components/ProductPopover'
import { ROLE_KEYS } from '~/config/constants'
import useAuth from '~/utils/auth'

import useStyle from './style'

const Header = ({ location }) => {
  const classes = useStyle()
  const { getUserInformation } = useAuth()

  const user = getUserInformation()
  const isLandingPage = location.pathname === '/'

  return (
    <AppBar position="fixed" className={classes.appbar}>
      <Toolbar classes={{ root: classes.toolbarRootOverrides }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
        >
          <Box display="flex" alignItems="center" zIndex="1100">
            {!isLandingPage && (
              <Box display="flex" mr={12}>
                <ProductPopover />
                <Link
                  data-testid="home-link"
                  to="/"
                  className={classes.homeLink}
                >
                  <img
                    className={classes.companyLogo}
                    src="/public/images/xapiens-logo-black.svg"
                    alt="header-logo"
                  />
                </Link>
              </Box>
            )}
            {user?.userAccess?.roleType !== ROLE_KEYS.SYS_ADMIN && (
              <Box
                display="flex"
                alignItems="center"
                ml={isLandingPage ? 3 : 12}
                color="black"
              >
                <img
                  className={classes.orgLogo}
                  src="/public/images/company/xapiens.svg"
                  alt="header-logo"
                />
                <Text component="div" size="sm" variant="paragraph" bold>
                  {user?.userOrg?.orgName?.toUpperCase()}
                </Text>
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Box hidden mr={6}>
              <NotificationSummary />
            </Box>
            <LogoutPopover />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default withRouter(Header)
