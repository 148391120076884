import { useMemo } from 'react'

import useAuth from '~/utils/auth'

import { getProductData, getHelpfulLinkData } from './helper'

const useCustom = () => {
  const { getUserInformation } = useAuth()

  const userInfo = getUserInformation()

  const productDatas = useMemo(() => getProductData(userInfo?.userProd), [
    userInfo,
  ])

  const helpfulLinkDatas = useMemo(
    () => getHelpfulLinkData(userInfo?.userAccess?.roleType),
    [userInfo],
  )

  return {
    data: {
      helpfulLinkDatas,
      productDatas,
    },
  }
}
export default useCustom
