import PropTypes from 'prop-types'
import React from 'react'

import { Box, Button as MUIButton } from '@material-ui/core'
import { Button, Loading, Text } from '@xti/frontend-kit-components'
import { Field, Form, Formik } from 'formik'
import { kebabCase } from 'lodash'

import CustomCheckbox from '~/components/CustomCheckbox'
import CustomTextField from '~/components/CustomTextField'
import { SCREEN_TYPE } from '~/config/screen'
import useCustom from '~/pages/Login/hooks'
import loginSchema from '~/pages/Login/schema'

import useStyle from './style'

const LoginMobile = ({ ssoLinks }) => {
  const { handler } = useCustom({ screen: SCREEN_TYPE.MOBILE })
  const classes = useStyle()
  const renderButtonSSO = (isSubmitting) => {
    if (ssoLinks.length > 0) {
      return ssoLinks.map((data) => (
        <Button
          disabled={isSubmitting}
          data-testid="microsoft-login-btn"
          href={data?.link}
          target="_self"
          text={`Login with ${data?.name}`}
          size="md"
          startIcon={
            <img
              src={`/public/icons/${kebabCase(data?.name)}-icon.svg`}
              alt={`${kebabCase(data?.name)}-icon`}
              height="30px"
              width="30px"
            />
          }
          width="100%"
          variant="secondary"
          key={`${kebabCase(data?.name)}-button`}
        />
      ))
    }
    return <Loading />
  }

  return (
    <Box
      display="flex"
      height="fit-content"
      flex={1}
      className={classes.container}
      flexDirection="column"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={1}
        pb={4}
      >
        <Box
          display="flex"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          className={classes.formWrapper}
        >
          <img
            className={classes.logo}
            src="/public/images/xapiens-logo-black.svg"
            alt="landing"
          />
          <Formik
            initialValues={{
              username: '',
              password: '',
              keepSignIn: false,
            }}
            validationSchema={loginSchema}
            onSubmit={handler.handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={classes.formSection}
                >
                  <Field
                    component={CustomTextField}
                    label="Username/Email"
                    labelPosition="vertical"
                    name="username"
                    placeholder="Username/Email"
                    size="sm"
                    width="100%"
                  />
                  <Field
                    component={CustomTextField}
                    label="Password"
                    labelPosition="vertical"
                    name="password"
                    placeholder="Password"
                    size="sm"
                    type="password"
                    width="100%"
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={6}
                  >
                    <Field
                      name="keepSignIn"
                      type="checkbox"
                      as={CustomCheckbox}
                      label={
                        <Text component="span" size="xxs" variant="paragraph">
                          Keep me signed in
                        </Text>
                      }
                    />
                    <Box display="flex" justifyContent="flex-end">
                      <MUIButton
                        className={classes.textLink}
                        onClick={handler.handleForget}
                        data-testid="forgot-password-btn"
                      >
                        <Text component="span" size="xxs" variant="paragraph">
                          Forgot Password
                        </Text>
                      </MUIButton>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={classes.buttonWrapper}
                  >
                    <Button
                      disabled={isSubmitting}
                      data-testid="login-btn"
                      type="submit"
                      text="Login"
                      size="md"
                      width="100%"
                    />
                    <Box display="flex" flex={1} justifyContent="center">
                      <Text component="div" size="xs" variant="paragraph">
                        OR
                      </Text>
                    </Box>
                    {renderButtonSSO(isSubmitting)}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  )
}

LoginMobile.defaultProps = {
  ssoLinks: [],
}

LoginMobile.propTypes = {
  ssoLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
}

export default LoginMobile
