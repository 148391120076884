import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useMutation } from '@apollo/react-hooks'
import { useAlert, useSearchQuery } from '@xti/frontend-kit-components'

import { ALERT_SIZE_MAPPING } from '~/config/screen'
import MUTATION_RESET_PASSWORD from '~/queries/auth/resetPassword'
import { customDelay as delay } from '~/utils/interval'

export const useResetPassSearchQueries = () => {
  const searchQuery = useSearchQuery()

  return {
    code: searchQuery.code || '',
  }
}

const useCustom = ({ screen }) => {
  const history = useHistory()
  const { handleOpenSnackbar } = useAlert()
  const search = useResetPassSearchQueries()

  const [resetPassword] = useMutation(MUTATION_RESET_PASSWORD)

  const handleSubmit = useCallback(async (values, { resetForm }) => {
    let errorData = {}
    if (values.password && search.code) {
      const data = {
        email: '',
        resetCode: search.code,
        newPass: values.password,
      }
      await resetPassword({
        variables: {
          resetPassData: data,
        },
      }).catch(async (err) => {
        errorData = JSON.parse(err.message)
        if (errorData.detail) {
          handleOpenSnackbar({
            size: ALERT_SIZE_MAPPING[screen],
            testid: 'error-reset-password-alert',
            title: `${errorData.code}: ${errorData.detail}`,
            variant: 'error',
          })
          resetForm()
          await delay(2000)
          history.push('/')
        }
      })
      if (!errorData.detail) {
        handleOpenSnackbar({
          size: ALERT_SIZE_MAPPING[screen],
          testid: 'success-reset-password-alert',
          title:
            'Password Reset Successful, Please Login Using Your New Password',
          variant: 'success',
        })
        resetForm()
        await delay(2000)
        history.push('/')
      }
    } else {
      handleOpenSnackbar({
        size: ALERT_SIZE_MAPPING[screen],
        testid: 'fail-reset-password-alert',
        title: 'Reset Password Failed: Please fill the Password',
        variant: 'error',
      })
    }
  }, [])

  useEffect(() => {
    if (!search.code) {
      history.push('/')
    }
  }, [])

  return {
    handler: {
      handleSubmit,
    },
  }
}
export default useCustom
