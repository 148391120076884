import PropTypes from 'prop-types'
import React from 'react'

import { Box } from '@material-ui/core'
import { Text } from '@xti/frontend-kit-components'

import AvatarBadge from '../AvatarBadge'

const ProfileInfo = ({ customColor, name, nameSize, description }) => (
  <Box display="flex" alignItems="center">
    <AvatarBadge name={name} color={customColor} />
    <Box display="flex" flexDirection="column" ml={3} color="black">
      <Text component="div" size={nameSize} variant="paragraph" bold>
        {name || 'No Name'}
      </Text>
      <Text component="div" size="xs" variant="paragraph">
        {description || 'No Description'}
      </Text>
    </Box>
  </Box>
)

ProfileInfo.defaultProps = {
  customColor: undefined,
  description: '',
  name: '',
  nameSize: 'sm',
}

ProfileInfo.propTypes = {
  customColor: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  nameSize: PropTypes.string,
}

export default ProfileInfo
