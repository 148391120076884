import { makeStyles } from '@material-ui/styles'
import { black } from '@xti/frontend-kit-components/colors'

const style = makeStyles((theme) => ({
  buttonProductWrapper: {
    '& > button:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  buttonProduct: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '10px',
    borderColor: black[200],
    padding: `0 ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
    '&:hover': {
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },
  imageProduct: {
    height: '60px',
  },
  paper: {
    boxShadow: '0px 2px 40px rgba(0, 0, 0, 0.1)',
  },
}))

export default style
