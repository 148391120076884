import React from 'react'

import { Box, Drawer } from '@material-ui/core'
import { Text } from '@xti/frontend-kit-components'
import { black } from '@xti/frontend-kit-components/colors'
import { startCase, upperCase } from 'lodash-es'

import { DRAWER_MENU_CATEGORIES } from '~/config/constants'

import useCustom from './hooks'
import useStyle from './style'

const DrawerMenu = () => {
  const { handler, state } = useCustom()
  const classes = useStyle()
  const renderIconLink = (iconLink, route) => {
    if (iconLink) {
      return (
        <img
          className={classes.logo}
          src={iconLink}
          alt={`${route.id}-drawer-logo`}
        />
      )
    }
    return <div className={classes.logo} />
  }
  const renderMenu = () => {
    const menuCategory = Object.keys(DRAWER_MENU_CATEGORIES)
    return menuCategory.map((menu) => {
      const routes = handler.handleGetRoutes(menu)
      if (routes.length > 0) {
        return (
          <div key={`drawer-category-${menu}`}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              alignContent="center"
              height="40px"
              width="100%"
              px={6}
              color={black[400]}
            >
              <Text component="div" size="xs" variant="paragraph" bold>
                {upperCase(DRAWER_MENU_CATEGORIES?.[menu])}
              </Text>
            </Box>
            {routes.map((route) => {
              let routeLink = route.id
              let iconLink = `/public/icons/${route.iconPath || route.id}.png`
              if (route.parentId) {
                routeLink = `${route.parentId}/${route.id}`
              }
              if (route.collapse) {
                iconLink = null
                if (!state.openNotificationCollapse) {
                  return null
                }
              }
              return (
                <Box
                  key={`drawer-menu-${route.id}`}
                  data-testid={`${route.id}-drawer-menu`}
                  className={`${!route.disabled && classes.menu} ${
                    state.activeMenu === route.id && classes.activeMenu
                  }`}
                  display="flex"
                  justifyContent="flex-start"
                  alignContent="center"
                  alignItems="center"
                  height="40px"
                  width="100%"
                  my={2}
                  px={6}
                  onClick={
                    route.disabled ? () => {} : handler.handleMenu(routeLink)
                  }
                >
                  {renderIconLink(iconLink, route)}
                  <Text component="div" size="xs" variant="paragraph">
                    {route.label || startCase(route.id)}
                  </Text>
                </Box>
              )
            })}
          </div>
        )
      }
      return null
    })
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box
        className={classes.drawerHeader}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      />
      {renderMenu()}
    </Drawer>
  )
}

export default DrawerMenu
