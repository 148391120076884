import { lazy } from 'react'

import { DRAWER_MENU_CATEGORIES } from '~/config/constants'

const AddPhone = lazy(() => import('~/pages/Settings/Profile/Mobile/AddPhone'))
const ChangeEmail = lazy(() => import('~/pages/Settings/ChangeEmail'))
const ChangePassword = lazy(() => import('~/pages/Settings/ChangePassword'))
const Profile = lazy(() => import('~/pages/Settings/Profile'))

export default [
  {
    id: 'profile',
    parentId: 'settings',
    title: 'Profile Page',
    path: '/settings/profile',
    component: Profile,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    category: DRAWER_MENU_CATEGORIES.SETTING,
  },
  {
    id: 'email',
    parentId: 'settings',
    title: 'Change Email Page',
    path: '/settings/email',
    component: ChangeEmail,
    exact: true,
    disabled: true,
    shownOnMenu: false,
    withHeader: true,
    category: DRAWER_MENU_CATEGORIES.SETTING,
  },
  {
    id: 'password',
    parentId: 'settings',
    title: 'Change Password Page',
    path: '/settings/password',
    component: ChangePassword,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    category: DRAWER_MENU_CATEGORIES.SETTING,
  },
  {
    id: 'add-phone',
    parentId: 'settings',
    title: 'Add Phone Page',
    path: '/settings/add-phone',
    component: AddPhone,
    exact: true,
    disabled: false,
    shownOnMenu: false,
    withHeader: true,
    mobileOnly: true,
    category: DRAWER_MENU_CATEGORIES.SETTING,
  },
]
