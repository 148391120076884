import { makeStyles } from '@material-ui/styles'

const style = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbarRootOverrides: {
    minHeight: '58px',
    maxHeight: '58px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  companyLogo: {
    width: '60px',
    height: '32px',
    marginLeft: theme.spacing(5),
  },
  orgLogo: {
    width: '28px',
    height: '20px',
    marginRight: theme.spacing(1),
  },
  homeLink: {
    display: 'flex',
  },
}))

export default style
