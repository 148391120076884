/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import React from 'react'

import { Box } from '@material-ui/core'
import {
  Visibility as VisibleIcon,
  VisibilityOff as NotVisibleIcon,
} from '@material-ui/icons'
import {
  componentGeneralSize,
  fieldToTextInput,
  Checkbox,
  Text,
  TextInput as ZxTextInput,
} from '@xti/frontend-kit-components'
import { fieldToTextField } from 'formik-material-ui'
import { kebabCase } from 'lodash-es'

import PasswordHint from '~/components/PasswordHint'

import { INPUT_LABEL_SIZE_MAPPING } from './helper'
import useCustom from './hooks'
import useStyle from './style'

const CustomTextField = ({
  disabled,
  endIcon,
  generate,
  handleClickEndIcon,
  hint,
  noLabel,
  placeholder,
  required,
  size,
  type,
  ...props
}) => {
  const { error, name, value } = fieldToTextInput(fieldToTextField(props))
  const { handler, state, ref } = useCustom({ name, value })
  const classes = useStyle({ disabled })

  const renderVisibilityIcon = state.showPassword ? VisibleIcon : NotVisibleIcon

  const renderGeneratePassword = (
    <Checkbox
      checked={state.generate}
      data-testid="checkbox-generate-password"
      handleChange={handler.handleGeneratePassword}
      label="Automatically Create Password"
      size={size}
    />
  )

  const renderHint = (
    <PasswordHint
      open={!!error}
      anchorEl={ref.inputRef.current}
      number={state.validator.hasNumber}
      character={state.validator.minLength}
      lowerCase={state.validator.hasLowerCase}
      upperCase={state.validator.hasUpperCase}
      special={state.validator.hasSpecial}
    />
  )

  const renderInput = (
    <ZxTextInput
      {...props}
      {...fieldToTextInput(fieldToTextField(props))}
      data-testid={kebabCase(name)}
      data-testid-helpertext={`error-helper-${kebabCase(name)}`}
      disabled={state.generate ? true : disabled}
      endIcon={type.includes('password') ? renderVisibilityIcon : endIcon}
      handleClickEndIcon={
        type.includes('password')
          ? handler.handleClickShowPassword
          : handleClickEndIcon
      }
      placeholder={placeholder}
      ref={hint ? ref.inputRef : undefined}
      required={required}
      size={size}
      type={state.showPassword ? 'text' : type}
      key={kebabCase(name)}
    />
  )

  const renderLabel = (
    <Box mb={1} className={classes.label}>
      <Text
        component="span"
        size={INPUT_LABEL_SIZE_MAPPING[size]}
        variant="paragraph"
      >
        {!noLabel && placeholder}
        {required && ' *'}
      </Text>
    </Box>
  )

  const renderCountryCode = (
    <Box display="flex" alignItems="center">
      <img
        className={classes.logo}
        src="/public/icons/flag-id.svg"
        alt="flag-id"
      />
      <Text
        component="span"
        size={INPUT_LABEL_SIZE_MAPPING[size]}
        variant="paragraph"
      >
        +62
      </Text>
    </Box>
  )

  if (type.includes('password')) {
    if (generate) {
      return (
        <Box display="flex" flexDirection="column">
          {hint && renderHint}
          {renderLabel}
          <Box mb={2}>{renderGeneratePassword}</Box>
          {renderInput}
        </Box>
      )
    }
    return (
      <>
        {hint && renderHint}
        {renderInput}
      </>
    )
  }
  if (name.includes('phone')) {
    return (
      <Box display="flex" flexDirection="column">
        {renderLabel}
        <Box display="flex" alignItems="center">
          {renderCountryCode}
          <Box ml={2} display="flex" flexDirection="column" flex={1}>
            {renderInput}
          </Box>
        </Box>
      </Box>
    )
  }
  return <>{renderInput}</>
}

CustomTextField.defaultProps = {
  disabled: false,
  endIcon: undefined,
  generate: false,
  handleClickEndIcon: undefined,
  hint: false,
  noLabel: undefined,
  placeholder: undefined,
  required: false,
  type: 'text',
}

CustomTextField.propTypes = {
  disabled: PropTypes.bool,
  endIcon: PropTypes.elementType,
  generate: PropTypes.bool,
  handleClickEndIcon: PropTypes.func,
  hint: PropTypes.bool,
  noLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(componentGeneralSize)).isRequired,
  type: PropTypes.string,
}

export default CustomTextField
