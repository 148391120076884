import PropTypes from 'prop-types'
import React from 'react'

import { Checkbox, FormControlLabel } from '@material-ui/core'
import { kebabCase } from 'lodash-es'

import useStyle from './style'

const CustomCheckBox = ({ checked, label, name, onChange }) => {
  const classes = useStyle()

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          name={name}
          onChange={onChange}
          inputProps={{
            'data-testid': `${kebabCase(name)}-checkbox`,
          }}
        />
      }
      classes={{ label: classes.labelRoot }}
      label={label}
    />
  )
}

CustomCheckBox.defaultProps = {
  label: '',
}

CustomCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CustomCheckBox
