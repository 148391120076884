import { makeStyles } from '@material-ui/styles'

import { colors } from '~/styles/theme'

const style = makeStyles((theme) => ({
  separator: {
    border: `1px solid ${colors.Concrete}`,
  },
  logo: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(3),
  },
  icon: {
    width: '20px',
    height: '20px',
  },
}))

export default style
