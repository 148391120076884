import React from 'react'

import { useMediaQuery } from '@material-ui/core'

import { MOBILE } from '~/config/screen'

import NewPasswordDesktop from './Desktop'
import NewPasswordMobile from './Mobile'

const NewPassword = () => {
  const matches = useMediaQuery(MOBILE.MAX_WIDTH)

  return matches ? <NewPasswordMobile /> : <NewPasswordDesktop />
}

export default NewPassword
