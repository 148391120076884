import PropTypes from 'prop-types'
import React, { useCallback, useContext, useState } from 'react'

import {
  client as zxAuthClient,
  cookies as zxAuthCookies,
} from '@xti/frontend-kit-auth/auth'

import { authHost } from '~/config'
import {
  ALLOWED_DOMAINS,
  AUTH_PROVIDER_TYPE,
  AUTH_PROVIDER_TYPE_MAPPING,
  DRAWER_MENU_KEYS,
  PERMISSION_MAPPINGS,
  ROLE_DRAWER_MENU_MAPPINGS,
  ROLE_KEYS,
  ROLE_MENU_MAPPINGS,
  SUBMENU_KEYS,
  USER_MENUS,
} from '~/config/constants'
import routes from '~/routes'
import client from '~/utils/graphql'

export const fetchProfileData = async () => {
  const data = await zxAuthClient.getProfileData(authHost)
  return data?.data?.auth?.getProfile
}

export const decodeJWTToUserData = async (token) => {
  if (token) {
    const profileData = await fetchProfileData()
    const userTokenData = zxAuthClient.decodeJWT(token)?.user_info
    if (profileData && userTokenData) {
      return {
        ID: profileData?.id,
        userID: profileData?.userID,
        userName: profileData?.userName,
        userAccess: profileData?.userAccess,
        userOrg: profileData?.userOrg,
        userProd: profileData?.userProd,
        enabled: profileData?.enabled,
        location: profileData?.location,
        mustChangePwd: profileData?.mustChangePwd,
        phone: profileData?.phone,
        authProvider: profileData?.authProvider,
        expireAt: userTokenData?.expireAt,
        issuedAt: userTokenData?.issuedAt,
        issuer: userTokenData?.issuer,
      }
    }
  }

  return null
}

export const AuthContext = React.createContext()
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(zxAuthCookies.getToken()?.token),
  )
  const [userData, setUserData] = useState(null)
  const [permissions, setPermissions] = useState([])
  const [totalUnread, setTotalUnread] = useState(0)
  const [menuType, setMenuType] = useState('')

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        menuType,
        permissions,
        setIsLoggedIn,
        setMenuType,
        setPermissions,
        setTotalUnread,
        setUserData,
        totalUnread,
        userData,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export const authLogin = (username, password) =>
  zxAuthClient
    .getRequestToken(authHost, {
      username,
      password,
    })
    .catch((e) => JSON.parse(e.message))

export const authLogout = async () => {
  const { token } = zxAuthCookies.getToken()
  const response = await zxAuthClient.doLogoutToken(authHost, token)

  return response
}

export const authRefresh = async () => {
  const { refreshToken } = zxAuthCookies.getToken()
  const data = await zxAuthClient.getRefreshToken(authHost, refreshToken)

  const token = data?.data?.token?.refreshToken?.token
  const newRefreshToken = data?.data?.token?.refreshToken?.refreshToken
  if (token) {
    zxAuthCookies.setToken(token, newRefreshToken, ALLOWED_DOMAINS)
  } else {
    zxAuthCookies.removeToken(ALLOWED_DOMAINS)

    window.location.assign('/')
  }

  window.location.reload()
}

const useAuth = () => {
  const {
    isLoggedIn,
    menuType,
    permissions,
    setIsLoggedIn,
    setMenuType,
    setPermissions,
    setTotalUnread,
    setUserData,
    totalUnread,
    userData,
  } = useContext(AuthContext)

  const fetchTotalUnreadNotificationData = useCallback(async () => {
    const { data } = await zxAuthClient.getTotalUnreadNotification(authHost)
    setTotalUnread(data?.inapp?.getMessage?.pageInfo?.totalUnread)
  }, [])

  const handleLogin = useCallback((token, refreshToken) => {
    zxAuthCookies.setToken(token, refreshToken, ALLOWED_DOMAINS)
    setIsLoggedIn(true)
  }, [])

  const handleLogout = useCallback(async () => {
    await authLogout().catch()
    setIsLoggedIn(false)
    zxAuthCookies.removeToken(ALLOWED_DOMAINS)
    await client.clearStore()
    window.location = '/'
  }, [])

  const loadUserProfileData = useCallback(async () => {
    const { token } = zxAuthCookies.getToken()
    try {
      const userMetaData = await decodeJWTToUserData(token)

      if (userMetaData) {
        setUserData(userMetaData)
        setPermissions(userMetaData?.userAccess?.permission || [])
      } else {
        handleLogout()
      }
    } catch (err) {
      handleLogout()
    }
  }, [])

  const getUserInformation = useCallback(() => {
    let user
    try {
      user = typeof userData === 'object' ? userData : JSON.parse(userData)
    } catch (e) {} // eslint-disable-line no-empty
    return user
  }, [userData])

  const checkPathPermission = useCallback(
    (destinationPath) => {
      let allow = false
      if (userData) {
        const userInfoData = getUserInformation()
        const userRole = userInfoData?.userAccess?.roleType
        const userPermissions = userInfoData?.userAccess?.permission
        const userSSO = userInfoData?.authProvider

        if (
          userPermissions.length > 0 &&
          (userRole === ROLE_KEYS.SYS_ADMIN ||
            userRole === ROLE_KEYS.CLIENT_ADMIN)
        ) {
          allow =
            userPermissions.indexOf(PERMISSION_MAPPINGS[destinationPath]) >= 0
          // ? bypass menu that doesn't require permission eg. profile, password
          if (
            allow ||
            USER_MENUS.includes(destinationPath) ||
            destinationPath === DRAWER_MENU_KEYS.ADMINISTRATOR ||
            destinationPath === DRAWER_MENU_KEYS.AUDIT_LOG
          ) {
            allow = ROLE_MENU_MAPPINGS[userRole].includes(destinationPath)
          }
        } else if (userRole === ROLE_KEYS.USER) {
          allow = USER_MENUS.includes(destinationPath)
        }
        // deny access to change password if user sso = external (!=1)
        if (
          AUTH_PROVIDER_TYPE_MAPPING[userSSO] !== AUTH_PROVIDER_TYPE.internal &&
          destinationPath === SUBMENU_KEYS.SETTING_PASSWORD
        ) {
          allow = false
        }
      }
      return allow
    },
    [userData],
  )

  const getMenusByRoleName = useCallback((userInfo) => {
    let drawerMenus = []
    const userRole = userInfo?.userAccess?.roleType
    const userSSO = userInfo?.authProvider
    if (userRole) {
      let menus = []
      menus = ROLE_DRAWER_MENU_MAPPINGS[userRole]
      if (AUTH_PROVIDER_TYPE_MAPPING[userSSO] !== AUTH_PROVIDER_TYPE.internal) {
        menus = menus.filter((menu) => menu !== SUBMENU_KEYS.SETTING_PASSWORD)
      }
      if (menus && menus.length > 0) {
        drawerMenus = menus
      }
    }
    return drawerMenus
  }, [])

  const getChildrenRoutesByMenus = (menus) => {
    const result = []
    if (menus && menus.length > 0) {
      routes.forEach((route) => {
        if (route.childrenRoutes && route.shownOnMenu) {
          const { childrenRoutes } = route
          childrenRoutes.forEach((childRoute) => {
            if (
              menus.includes(`${route.id}/${childRoute.id}`) &&
              childRoute.shownOnMenu
            ) {
              result.push({
                ...childRoute,
              })
            }
          })
        }
      })
    }
    return result
  }

  const getDrawerRoutesByMenus = useCallback((menus) => {
    let result = []
    if (menus && menus.length > 0) {
      result = routes.filter(
        (route) => menus.includes(route.id) && route.shownOnMenu,
      )
      const childrenRoutes = getChildrenRoutesByMenus(menus)
      result = result.concat(childrenRoutes)
    }
    return result
  }, [])

  return {
    checkPathPermission,
    fetchTotalUnreadNotificationData,
    getDrawerRoutesByMenus,
    getMenusByRoleName,
    getUserInformation,
    handleLogin,
    handleLogout,
    isLoggedIn,
    loadUserProfileData,
    menuType,
    permissions,
    setMenuType,
    setTotalUnread,
    setUserData,
    totalUnread,
    userData,
  }
}

export default useAuth
