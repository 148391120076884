import { makeStyles } from '@material-ui/styles'

import { colors } from '~/styles/theme'

const style = makeStyles((theme) => ({
  appbar: {
    background: "url('/public/images/background-header-mobile.svg')",
    backgroundColor: colors.Rosewood,
    backgroundPosition: 'left-top',
    backgroundRepeat: 'no-repeat',
  },
  toolbarRootOverrides: {
    minHeight: ({ expandHeader }) => (expandHeader ? '175px' : '56px'),
    maxHeight: ({ expandHeader }) => (expandHeader ? '175px' : '56px'),
    height: ({ expandHeader }) => (expandHeader ? '175px' : undefined),
    alignItems: ({ expandHeader }) => (expandHeader ? 'start' : 'center'),
    paddingTop: ({ expandHeader }) =>
      expandHeader ? theme.spacing(3) : theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

export default style
