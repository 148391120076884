import { lazy } from 'react'

import { DRAWER_MENU_CATEGORIES } from '~/config/constants'

const NotificationLog = lazy(() => import('~/pages/Log/NotificationLog/List'))

export default [
  {
    id: 'notification',
    parentId: 'log',
    label: 'Notification Log',
    title: 'Notification Log Page',
    path: '/log/notification/:subType',
    component: NotificationLog,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    category: DRAWER_MENU_CATEGORIES.LOG,
    iconPath: 'notification-log',
  },
]
