import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { DRAWER_MENU_CATEGORIES, ROLE_KEYS } from '~/config/constants'
import useAuth from '~/utils/auth'

import { getProductData } from './helper'

const useCustom = () => {
  const history = useHistory()
  const {
    getDrawerRoutesByMenus,
    getMenusByRoleName,
    getUserInformation,
  } = useAuth()

  const userInfo = getUserInformation()

  const menus = useMemo(() => getMenusByRoleName(userInfo), [userInfo])
  const drawerRoutes = useMemo(
    () =>
      getDrawerRoutesByMenus(menus).filter(
        (data) => data.id !== 'notification-settings',
      ),
    [menus],
  )

  const productData = useMemo(() => getProductData(userInfo?.userProd), [
    userInfo,
  ])

  const handleGetRoutes = useCallback(
    (routeType) =>
      drawerRoutes.filter(
        (route) => route.category === DRAWER_MENU_CATEGORIES?.[routeType],
      ),
    [],
  )
  const handleMenu = useCallback(
    (menu) => () => {
      history.push({ pathname: `/${menu}` })
    },
    [],
  )

  useEffect(() => {
    // ? if user have one product, always redirect to that product upon login
    if (
      productData.length === 1 &&
      userInfo?.mustChangePwd === false &&
      userInfo?.userAccess.roleType === ROLE_KEYS.USER
    ) {
      window.location.assign(`${productData[0].path}`)
    }
  }, [productData, userInfo])

  return {
    data: {
      userInfo,
      productData,
    },
    handler: {
      handleGetRoutes,
      handleMenu,
    },
  }
}
export default useCustom
