import React from 'react'

import { Box } from '@material-ui/core'
import { KeyboardArrowRight as ArrowIcon } from '@material-ui/icons'
import { Text } from '@xti/frontend-kit-components'
import { startCase, upperCase } from 'lodash'

import ProductSwitchMobile from '~/components/ProductSwitchMobile'
import ProfileInfo from '~/components/ProfileInfo'
import { DRAWER_MENU_CATEGORIES } from '~/config/constants'

import useCustom from './hooks'
import useStyle from './style'

const MyAccount = () => {
  const { data, handler } = useCustom()
  const classes = useStyle()

  const renderIconLink = (iconLink, route) => {
    if (iconLink) {
      return (
        <img
          className={classes.logo}
          src={iconLink}
          alt={`${route.id}-menu-logo`}
        />
      )
    }
    return <div className={classes.logo} />
  }
  const renderMenu = () => {
    let filteredMenu = (({ SETTING, NOTIFICATIONS }) => ({
      SETTING,
      NOTIFICATIONS,
    }))(DRAWER_MENU_CATEGORIES)
    filteredMenu = Object.keys(filteredMenu)
    return filteredMenu.map((menu) => {
      const routes = handler.handleGetRoutes(menu)
      if (routes.length > 0) {
        return (
          <div key={`menu-category-${menu}`}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              alignContent="center"
              height="40px"
              width="100%"
              p={5}
            >
              <Text component="div" size="xs" variant="paragraph">
                {upperCase(DRAWER_MENU_CATEGORIES?.[menu])}
              </Text>
            </Box>
            <Box mx={4}>
              {routes.map((route) => {
                let routeLink = route.id
                const iconLink = `/public/icons/${
                  route.iconPath || route.id
                }.png`
                if (route.parentId) {
                  routeLink = `${route.parentId}/${route.id}`
                }
                return (
                  <div key={`menu-${route.id}`}>
                    <Box
                      data-testid={`${route.id}-menu`}
                      display="flex"
                      justifyContent="flex-start"
                      alignContent="center"
                      alignItems="center"
                      width="100%"
                      py={3}
                      onClick={
                        route.disabled
                          ? () => {}
                          : handler.handleMenu(routeLink)
                      }
                    >
                      {renderIconLink(iconLink, route)}
                      <Text component="div" size="xs" variant="paragraph">
                        {route.label || startCase(route.id)}
                      </Text>
                      <Box display="flex" flex={1} justifyContent="flex-end">
                        <ArrowIcon
                          classes={{
                            root: classes.icon,
                          }}
                        />
                      </Box>
                    </Box>
                    <div className={classes.separator} />
                  </div>
                )
              })}
            </Box>
          </div>
        )
      }
      return null
    })
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      flex={1}
      bgcolor="white"
    >
      <Box p={5}>
        <ProfileInfo
          name={data?.userInfo?.userName}
          description={data?.userInfo?.userID}
        />
      </Box>
      <div className={classes.separator} />
      {renderMenu()}
      <ProductSwitchMobile data={data?.productData} />
    </Box>
  )
}

export default MyAccount
