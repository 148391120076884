import React from 'react'

import { Box } from '@material-ui/core'
import { Button, Text } from '@xti/frontend-kit-components'
import { Field, Formik, Form } from 'formik'

import CustomTextField from '~/components/CustomTextField'
import { SCREEN_TYPE } from '~/config/screen'
import useCustom from '~/pages/NewPassword/hooks'
import resetSchema from '~/pages/NewPassword/schema'

import useStyle from './style'

const NewPasswordDesktop = () => {
  const { handler } = useCustom({ screen: SCREEN_TYPE.DESKTOP })
  const classes = useStyle()

  return (
    <Box display="flex" height="100%" flex={1} className={classes.container}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={1}
        ml={8}
      >
        <Box
          display="flex"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          className={classes.formWrapper}
        >
          <img
            className={classes.logo}
            src="/public/images/xapiens-logo-black.svg"
            alt="landing"
          />
          <Text component="div" size="md" variant="paragraph" bold>
            Enter Your New Password
          </Text>
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validationSchema={resetSchema}
            onSubmit={handler.handleSubmit}
          >
            {({ errors, isSubmitting }) => (
              <Form>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={classes.formSection}
                  mt={8}
                >
                  <Field
                    component={CustomTextField}
                    error={!!errors.password}
                    helperText={errors.password}
                    label="New Password"
                    labelPosition="vertical"
                    name="password"
                    placeholder="New Password"
                    size="lg"
                    type="password"
                    width="100%"
                    hint
                  />
                  <Field
                    component={CustomTextField}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    label="Confirm New Password"
                    labelPosition="vertical"
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                    size="lg"
                    type="password"
                    width="100%"
                  />
                  <Box display="flex" flexDirection="column" mt={8}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      text="Reset Password"
                      size="lg"
                      width="100%"
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={1}
        className={classes.background}
      />
    </Box>
  )
}

export default NewPasswordDesktop
