import { useCallback, useState, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { disableOtp } from '~/config'
import { DRAWER_MENU_CATEGORIES } from '~/config/constants'
import useAuth from '~/utils/auth'

const useCustom = () => {
  const history = useHistory()
  const {
    getUserInformation,
    getDrawerRoutesByMenus,
    getMenusByRoleName,
    handleLogout,
  } = useAuth()
  const user = getUserInformation()

  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverShown, setPopoverShown] = useState(false)
  const [showSettingMenu, setShowSettingMenu] = useState(false)
  const [anchorElPopper, setAnchorElPopper] = useState(null)
  const [openPopper, setOpenPopper] = useState(false)
  const [warnPhoneNumber, setWarnPhoneNumber] = useState(false)

  const menus = useMemo(() => getMenusByRoleName(user), [user])
  const drawerRoutes = useMemo(() => getDrawerRoutesByMenus(menus), [menus])

  const settingRoutes = useMemo(
    () =>
      drawerRoutes.filter(
        (route) => route.category === DRAWER_MENU_CATEGORIES.SETTING,
      ),
    [drawerRoutes],
  )

  const handleClose = () => {
    setPopoverShown(false)
  }

  const handleSettingChild = useCallback((value) => {
    history.push({
      pathname: `/${value}`,
    })
  }, [])

  const handlePopoverShown = (value) => (e) => {
    setPopoverShown(value)
    setAnchorEl(e.currentTarget)
  }

  const handleSettingMenuShown = useCallback((value) => {
    setShowSettingMenu(value)
  }, [])

  const handleOpenPopper = (value) => (e) => {
    setAnchorElPopper(e.currentTarget)
    setOpenPopper(value)
  }

  useEffect(() => {
    // ? When disable OTP = 1 phone number not required
    if (disableOtp !== '1' && user && !user?.phone) {
      setWarnPhoneNumber(true)
    } else {
      setWarnPhoneNumber(false)
    }
  }, [user])

  return {
    handler: {
      handleClose,
      handlePopoverShown,
      handleSettingChild,
      handleSettingMenuShown,
      handleLogout,
      handleOpenPopper,
    },
    state: {
      anchorEl,
      popoverShown,
      showSettingMenu,
      anchorElPopper,
      openPopper,
      warnPhoneNumber,
    },
    data: {
      profileInfo: user,
      settingRoutes,
    },
  }
}

export default useCustom
