import { makeStyles } from '@material-ui/styles'

const style = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
  },
  logo: {
    width: '120px',
    marginBottom: theme.spacing(5),
  },
  formWrapper: {
    flexBasis: '75%',
    height: 'auto',
    width: '100%',
  },
  formSection: {
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  textLink: {
    textTransform: 'none',
    textDecoration: 'underline',
  },
  background: {
    width: '90%',
    borderRadius: '0 0 0 15px',
  },
  buttonWrapper: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}))

export default style
