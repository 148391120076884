import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { cookies as zxAuthCookies } from '@xti/frontend-kit-auth/auth'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { graphQLHost, graphQLSubscriptionHost } from '~/config'
import { UNAUTHORIZED } from '~/config/constants'
import { authRefresh } from '~/utils/auth'

const cache = new InMemoryCache()

const expiredTokenMiddleware = onError(
  ({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const unauthorized = graphQLErrors?.find(
        (err) => err.message === UNAUTHORIZED,
      )
      if (unauthorized) {
        authRefresh().catch()
      }
    }

    return forward(operation)
  },
)

const getToken = (type) => {
  const res = ''
  const { token } = zxAuthCookies.getToken()
  if (token) {
    if (type === 'withBearer') {
      return `Bearer ${token}`
    }
    return token
  }
  return res
}

const authMiddleWare = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    headers: {
      Authorization: getToken('withBearer'),
    },
  }))

  return forward(operation)
})

const queryHttpLink = new HttpLink({
  uri: `${graphQLHost}/query`,
})

const httpLink = from([expiredTokenMiddleware, authMiddleWare, queryHttpLink])

export const createClient = (isLoggedIn) => {
  let splitLink = httpLink
  let subscriptionClient

  // Only apply our subscription client when the user is logged in
  if (isLoggedIn) {
    subscriptionClient = new SubscriptionClient(
      `${graphQLSubscriptionHost}?header=${getToken()}`,
      {
        reconnect: true,
        reconnectionAttempts: 10,
        connectionParams: () => ({
          headers: {
            'Sec-Websocket-Protocol': 'graphql-ws',
          },
        }),
        lazy: true,
      },
    )
    const wsLink = new WebSocketLink(subscriptionClient)

    splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )
  }

  const apolloClient = new ApolloClient({
    link: splitLink,
    cache,
  })

  return [apolloClient, subscriptionClient]
}

export const graphqlDefaultOptions = {
  pollInterval: 1000 * 60 * 5, // 5 Minutes
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'no-cache',
}

export default createClient(false)[0]
