import { makeStyles } from '@material-ui/styles'

const style = makeStyles((theme) => ({
  titleLink: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginTop: `-${theme.spacing(1)}`,
    },
    color: theme.palette.text.secondary,
  },
  logo: {
    marginLeft: theme.spacing(2),
  },
}))

export default style
