import { makeStyles } from '@material-ui/styles'
import { palette } from '@xti/frontend-kit-components/colors'

const style = makeStyles((theme) => ({
  avatar: {
    backgroundColor: ({ color }) => palette[color],
    color: ({ contrastText }) =>
      contrastText ? theme.palette.text.secondary : theme.palette.common.white,
  },
}))

export default style
