import { makeStyles } from '@material-ui/styles'

const style = makeStyles((theme) => ({
  contentHeader: {
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}))

export default style
