import React from 'react'
import { withRouter } from 'react-router-dom'

import { IconButton } from '@material-ui/core'
import { NotificationsOutlined as NotifIcon } from '@material-ui/icons'
import {
  Badge,
  NotificationSummary as NotificationPopup,
} from '@xti/frontend-kit-components'
import { palette } from '@xti/frontend-kit-components/colors'

import { authHost } from '~/config'

import useCustom from './hooks'

const NotificationSummary = () => {
  const { data, state, handler } = useCustom()

  return (
    <>
      <IconButton
        data-testid="notification-summary-btn"
        size="small"
        onClick={handler.handleOpen}
      >
        <Badge variant="number" value={data?.totalUnread}>
          <NotifIcon htmlColor={palette.Black} style={{ fontSize: 24 }} />
        </Badge>
      </IconButton>
      <NotificationPopup
        open={state.showPopover}
        anchorEl={state.anchorEl}
        handleChangeTotalUnread={handler.handleChangeTotalUnread}
        handleClose={handler.handleClose}
        clientHost={authHost}
      />
    </>
  )
}

export default withRouter(NotificationSummary)
