import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  ADMINISTRATOR_MENUS,
  AUDIT_LOG_MENUS,
  DRAWER_MENU_CATEGORIES,
  DRAWER_MENU_KEYS,
  DRAWER_MENU_TYPES,
  USER_MENUS,
} from '~/config/constants'
import useAuth from '~/utils/auth'

const useCustom = () => {
  const history = useHistory()
  const location = useLocation()
  const {
    getDrawerRoutesByMenus,
    getMenusByRoleName,
    getUserInformation,
    menuType,
  } = useAuth()
  const userInfo = getUserInformation()

  const menus = useMemo(
    () =>
      getMenusByRoleName(userInfo).filter((menu) => {
        switch (menuType) {
          case DRAWER_MENU_KEYS.ADMINISTRATOR: {
            return ADMINISTRATOR_MENUS.includes(menu)
          }
          case DRAWER_MENU_KEYS.USER: {
            return USER_MENUS.includes(menu)
          }
          case DRAWER_MENU_KEYS.AUDIT_LOG: {
            return AUDIT_LOG_MENUS.includes(menu)
          }
          default:
            break
        }
        return true
      }),
    [userInfo, menuType],
  )

  const [activeMenu, setActiveMenu] = useState()
  const [openNotificationCollapse, setOpenNotificationCollapse] = useState(
    false,
  )

  const drawerRoutes = useMemo(() => getDrawerRoutesByMenus(menus), [menus])

  const handleMenu = useCallback(
    (menu) => () => {
      history.push({ pathname: `/${menu}` })
    },
    [],
  )

  const handleGetRoutes = useCallback(
    (routeType) =>
      drawerRoutes.filter(
        (route) =>
          route.category === DRAWER_MENU_CATEGORIES?.[routeType] &&
          route.shownOnMenu,
      ),
    [drawerRoutes],
  )

  useEffect(() => {
    const pathname = location.pathname.split('/').filter((path) => path !== '')
    if (DRAWER_MENU_TYPES[pathname[0]] === 'category') {
      setActiveMenu(pathname[1])
    } else {
      setActiveMenu(pathname[0])
    }
    // check path is app-settings
    if (pathname[0] === 'app-settings') {
      setOpenNotificationCollapse(true)
    } else {
      setOpenNotificationCollapse(false)
    }
  }, [location.pathname])

  return {
    handler: {
      handleGetRoutes,
      handleMenu,
    },
    state: {
      activeMenu,
      openNotificationCollapse,
    },
  }
}

export default useCustom
