import { lazy } from 'react'

import { DRAWER_MENU_CATEGORIES } from '~/config/constants'
import appSettingRoutes from '~/routes/app-settings'
import logRoutes from '~/routes/log'
import notificationRoutes from '~/routes/notifications'
import settingsRoutes from '~/routes/settings'

const AppSetting = lazy(() => import('~/pages/AppSetting'))
const AuditLog = lazy(() => import('~/pages/AuditLog'))
const Group = lazy(() => import('~/pages/Group'))
const Organization = lazy(() => import('~/pages/Organization'))
const Role = lazy(() => import('~/pages/Role'))
const User = lazy(() => import('~/pages/User'))
const Policy = lazy(() => import('~/pages/Policy'))
const Settings = lazy(() => import('~/pages/Settings'))
const Log = lazy(() => import('~/pages/Log'))
const Notifications = lazy(() => import('~/pages/Notification'))

export default [
  {
    id: 'organization',
    title: 'Organization Page',
    path: '/organization/:type',
    component: Organization,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    userAccess: false,
    clientAdminAccess: true,
    sysAdminAccess: true,
    category: DRAWER_MENU_CATEGORIES.MANAGEMENT,
  },
  {
    id: 'user',
    title: 'User Page',
    path: '/user/:type',
    component: User,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    userAccess: false,
    clientAdminAccess: true,
    sysAdminAccess: true,
    category: DRAWER_MENU_CATEGORIES.MANAGEMENT,
  },
  {
    id: 'policy',
    title: 'Policy Page',
    path: '/policy/:type',
    component: Policy,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    userAccess: false,
    clientAdminAccess: false,
    sysAdminAccess: true,
    category: DRAWER_MENU_CATEGORIES.MANAGEMENT,
  },
  {
    id: 'group',
    title: 'Group Page',
    path: '/group/:type',
    component: Group,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    userAccess: false,
    clientAdminAccess: true,
    sysAdminAccess: false,
    category: DRAWER_MENU_CATEGORIES.MANAGEMENT,
  },
  {
    id: 'role',
    title: 'Role Page',
    path: '/role/:type',
    component: Role,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    userAccess: false,
    clientAdminAccess: true,
    sysAdminAccess: false,
    category: DRAWER_MENU_CATEGORIES.ACCESS,
  },
  {
    id: 'settings',
    title: 'Settings Page',
    path: '/settings/:type',
    component: Settings,
    childrenRoutes: settingsRoutes,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    userAccess: true,
    clientAdminAccess: true,
    sysAdminAccess: true,
    category: DRAWER_MENU_CATEGORIES.SETTING,
  },
  {
    id: 'app-settings',
    title: 'Application Settings Page',
    path: '/app-settings/:type/:subType',
    component: AppSetting,
    childrenRoutes: appSettingRoutes,
    exact: true,
    disabled: true,
    shownOnMenu: false,
    withHeader: true,
    userAccess: false,
    clientAdminAccess: true,
    sysAdminAccess: false,
    category: DRAWER_MENU_CATEGORIES.APP_SETTING,
  },
  {
    id: 'log',
    title: 'Log Page',
    path: '/log/:type/:subType',
    component: Log,
    childrenRoutes: logRoutes,
    exact: true,
    disabled: true,
    shownOnMenu: false,
    withHeader: true,
    userAccess: false,
    clientAdminAccess: true,
    sysAdminAccess: false,
    category: DRAWER_MENU_CATEGORIES.LOG,
  },
  {
    id: 'notifications',
    title: 'Notifications Page',
    path: '/notifications/:type',
    component: Notifications,
    childrenRoutes: notificationRoutes,
    exact: true,
    disabled: true,
    shownOnMenu: false,
    withHeader: true,
    userAccess: true,
    clientAdminAccess: true,
    sysAdminAccess: true,
    category: DRAWER_MENU_CATEGORIES.NOTIFICATIONS,
  },
  {
    id: 'audit-log',
    title: 'Audit Log Page',
    path: '/audit-log',
    component: AuditLog,
    childrenRoutes: notificationRoutes,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    userAccess: false,
    clientAdminAccess: true,
    sysAdminAccess: false,
    category: DRAWER_MENU_CATEGORIES.REPORT,
  },
]
