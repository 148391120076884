import { ROLE_KEYS, IDM_ID } from '~/config/constants'

export const getProductData = (roleType, userProd = [], zxProduct = []) => {
  const res = []
  const products = [...zxProduct]
  if (roleType === ROLE_KEYS.CLIENT_ADMIN) {
    products.push(IDM_ID)
  }
  if (userProd.length > 0) {
    products.forEach((data) => {
      const selectedProduct = userProd.find(
        (product) => product?.prodID === data,
      )
      if (selectedProduct) {
        res.push({
          id: selectedProduct?.prodID,
          name: selectedProduct?.prodName,
          path: selectedProduct?.prodPath,
        })
      }
    })
  }
  return res
}

export default [getProductData]
