import React from 'react'

import { Box } from '@material-ui/core'
import { Text } from '@xti/frontend-kit-components'

import useCustom from './hooks'

const SSOLogin = () => {
  useCustom()

  return (
    <Box px={7} py={4}>
      <Text component="div" size="sm" variant="paragraph">
        We&apos;ll be redirecting you in a few seconds...
      </Text>
    </Box>
  )
}

export default SSOLogin
