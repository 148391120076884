import { palette } from '@xti/frontend-kit-components/colors'
import tinycolor from 'tinycolor2'

const getRandomColorIndex = (datas) => Math.floor(Math.random() * datas.length)

export const getRandomColor = () => {
  const filteredColors = Object.keys(palette).filter(
    (data) => !data.includes('Light'),
  )
  let randomIndex = getRandomColorIndex(filteredColors)
  let selectedColor = tinycolor(palette[filteredColors[randomIndex]])
  // get another color, when color is too light
  while (selectedColor.getBrightness() >= 220) {
    randomIndex = getRandomColorIndex(filteredColors)
    selectedColor = tinycolor(palette[filteredColors[randomIndex]])
  }
  return filteredColors[randomIndex]
}

export default {
  getRandomColor,
}
