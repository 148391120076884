import PropTypes from 'prop-types'
import React from 'react'

import { Box, Button } from '@material-ui/core'
import { Text } from '@xti/frontend-kit-components'
import { kebabCase } from 'lodash-es'

import useCustom from './hooks'
import useStyle from './style'

const ProductSwitchMobile = ({ data }) => {
  const { handlePath } = useCustom()
  const classes = useStyle()

  return (
    <Box display="flex" flexDirection="column" py={3} px={6}>
      <Text component="div" size="xs" variant="paragraph">
        PRODUCTS
      </Text>
      <Box
        display="flex"
        flexDirection="column"
        className={classes.buttonProductWrapper}
        mt={4}
        px={1}
      >
        {data.map((product) => (
          <Button
            data-testid={`${kebabCase(product.name)}-popover-btn`}
            className={classes.buttonProduct}
            onClick={handlePath(product.path)}
            key={`button-${kebabCase(product.name)}-${product.id}`}
          >
            <img
              className={classes.imageProduct}
              alt={kebabCase(product.name)}
              src={`/public/images/products/${product.img}`}
            />
          </Button>
        ))}
      </Box>
    </Box>
  )
}

ProductSwitchMobile.defaultProps = {
  data: [],
}

ProductSwitchMobile.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      img: PropTypes.string.isRequired,
    }),
  ),
}

export default ProductSwitchMobile
