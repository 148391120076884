export const MOBILE = {
  MAX_WIDTH: '(max-width: 799px)',
}

export const TABLET = {
  MIN_WIDTH: '(min-width: 800px)',
  MAX_WIDTH: '(max-width: 1366px)',
}

export const DESKTOP = {
  MIN_WIDTH: '(min-width: 1367px)',
  MAX_WIDTH: '(max-width: 1920px)',
  MAX_WIDTH_MEDIUM: '(max-width: 1680px)',
  MIN_WIDTH_LARGE: '(min-width: 1681px)',
}

export const DRAWER_WIDTH = '224px'

export const SCREEN_TYPE = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
}

export const ALERT_SIZE_MAPPING = {
  MOBILE: 'sm',
  DESKTOP: 'lg',
}
