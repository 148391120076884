import { lazy } from 'react'

import { DRAWER_MENU_CATEGORIES } from '~/config/constants'

const NotificationPermission = lazy(() =>
  import('~/pages/AppSetting/NotificationPermission'),
)
const RecipientGroup = lazy(() => import('~/pages/AppSetting/RecipientGroup'))

export default [
  {
    id: 'notifications',
    parentId: 'app-settings',
    label: 'Notifications',
    title: 'Notification Page',
    path: '/app-settings/notifications/:subType',
    component: NotificationPermission,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    category: DRAWER_MENU_CATEGORIES.APP_SETTING,
  },
  {
    id: 'recipient',
    parentId: 'app-settings',
    label: 'Recipient Group',
    title: 'Recipient Group Page',
    path: '/app-settings/recipient/:subType',
    component: RecipientGroup,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    category: DRAWER_MENU_CATEGORIES.APP_SETTING,
    collapse: true,
  },
]
