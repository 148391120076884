import { makeStyles } from '@material-ui/core/styles'

import { DRAWER_WIDTH } from '~/config/screen'
import { colors } from '~/styles/theme'

const style = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  menu: {
    cursor: 'pointer',
  },
  activeMenu: {
    borderRight: `5px solid ${colors.HavelockBlue}`,
  },
  logo: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(3),
  },
}))

export default style
