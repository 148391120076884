import { useEffect, useState, useRef, useCallback } from 'react'

import { useFormikContext } from 'formik'
import { generate as generatePass } from 'generate-password'

import { validatePass } from '~/utils/string'

const useCustom = ({ name, value }) => {
  const { setFieldValue } = useFormikContext()
  const [generate, setGenerate] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const inputRef = useRef(null)
  const [validator, setValidator] = useState({})

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev)
  }, [])

  const handleGeneratePassword = useCallback(() => {
    setGenerate(!generate)
    if (!generate) {
      setShowPassword(true)
      setFieldValue(
        name,
        generatePass({
          length: 8,
          numbers: true,
          symbols: true,
          strict: true,
        }),
      )
    } else {
      setFieldValue(name, '')
    }
  }, [generate, name])

  useEffect(() => {
    setValidator(validatePass(value))
  }, [value])

  return {
    ref: {
      inputRef,
    },
    handler: {
      handleClickShowPassword,
      handleGeneratePassword,
    },
    state: {
      generate,
      showPassword,
      validator,
    },
  }
}
export default useCustom
