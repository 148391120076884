import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { SnackProvider } from '@xti/frontend-kit-components'
import FontFaceObserver from 'fontfaceobserver'
import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import 'react-dates/initialize'

import GraphqlProvider from '~/components/GraphqlProvider'
import IndexPage from '~/pages/index'
import GlobalStyles from '~/styles/global'
import '~/styles/reactDatesOverride.css'
import theme from '~/styles/theme'
import { AuthProvider } from '~/utils/auth'
import errorLogger from '~/utils/error-logger'
import { ServiceWorkerProvider } from '~/utils/service-worker'

JavascriptTimeAgo.locale(en)

// Observe loading of Poppins
const poppinsObserver = new FontFaceObserver('Poppins', {})
poppinsObserver.load().then(() => {
  document.body.classList.add('fontLoaded')
})

// Init error logger on production env
if (process.env.NODE_ENV === 'production') {
  errorLogger.init()
}

ReactDOM.render(
  <ServiceWorkerProvider>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <AuthProvider>
          <GraphqlProvider>
            <BrowserRouter>
              <SnackProvider>
                <IndexPage />
              </SnackProvider>
            </BrowserRouter>
          </GraphqlProvider>
        </AuthProvider>
      </>
    </ThemeProvider>
  </ServiceWorkerProvider>,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept()
}

if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install() // eslint-disable-line global-require
}
