import gql from 'graphql-tag'

const query = gql`
  mutation resetPassword($resetPassData: ResetPasswordPayload!) {
    auth {
      resetPassword(data: $resetPassData) {
        status
        message
      }
    }
  }
`

export default query
