import React from 'react'
import { withRouter } from 'react-router-dom'

import { Box, Button, IconButton, Popover } from '@material-ui/core'
import {
  Menu as MenuIcon,
  HomeOutlined as HomeIcon,
  ExitToApp as LogoutIcon,
  Close as CloseIcon,
  SettingsOutlined as SettingsIcon,
} from '@material-ui/icons'
import { Text } from '@xti/frontend-kit-components'
import { palette } from '@xti/frontend-kit-components/colors'
import { startCase } from 'lodash-es'

import ProfileInfo from '../ProfileInfo'

import useCustom from './hooks'
import useStyle from './style'

const HeaderPopoverMobile = () => {
  const classes = useStyle()
  const { data, state, handler } = useCustom()

  return (
    <Box display="flex" justifyContent="flex-start">
      <IconButton
        size="small"
        onClick={handler.handlePopoverShown(!state.popoverShown)}
      >
        <MenuIcon htmlColor="white" />
      </IconButton>
      <Popover
        open={state.popoverShown}
        anchorReference="none"
        anchorPosition={{
          left: 0,
          top: 0,
        }}
        onClose={handler.handleClose}
        marginThreshold={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box m={5}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                size="small"
                onClick={handler.handlePopoverShown(!state.popoverShown)}
              >
                <CloseIcon htmlColor="black" />
              </IconButton>
            </Box>
            <ProfileInfo
              name={data?.profileInfo?.userName}
              description={data?.profileInfo?.userID}
            />
          </Box>
          <div className={classes.separator} />
          <Box
            className={classes.popoverWrapper}
            m={5}
            display="flex"
            flexDirection="column"
          >
            <Button
              data-testid="home-popover-btn"
              className={classes.button}
              onClick={() => handler.handleRoute()}
            >
              <HomeIcon
                fontSize="small"
                style={{ color: palette.HavelockBlue }}
              />
              <Box ml={2}>
                <Text component="div" bold size="xxs" variant="paragraph">
                  Home
                </Text>
              </Box>
            </Button>
            {data?.settingRoutes.length > 0 && (
              <Button
                data-testid="settings-popover-btn"
                className={classes.button}
                onClick={() =>
                  handler.handleSettingMenuShown(!state.showSettingMenu)
                }
              >
                <SettingsIcon
                  fontSize="small"
                  style={{ color: palette.HavelockBlue }}
                />
                <Box ml={2}>
                  <Text component="div" bold size="xxs" variant="paragraph">
                    Settings
                  </Text>
                </Box>
              </Button>
            )}
            {state.showSettingMenu &&
              data.settingRoutes.map((route) => (
                <Button
                  className={classes.button}
                  onClick={() =>
                    handler.handleRoute(`${route.parentId}/${route.id}`)
                  }
                  key={`button-${route.id}`}
                  data-testid={`${route.id}-settings-popover-btn`}
                >
                  <Box ml={8}>
                    <Text component="div" bold size="xxs" variant="paragraph">
                      {startCase(route.id)}
                    </Text>
                  </Box>
                </Button>
              ))}
            <Button
              data-testid="logout-popover-btn"
              className={classes.button}
              onClick={handler.handleLogout}
            >
              <LogoutIcon
                fontSize="small"
                style={{ color: palette.HavelockBlue }}
              />
              <Box ml={2}>
                <Text component="div" bold size="xxs" variant="paragraph">
                  Logout
                </Text>
              </Box>
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

export default withRouter(HeaderPopoverMobile)
