import { useCallback, useMemo, useState } from 'react'

import { PRODUCT } from '~/config/constants'
import useAuth from '~/utils/auth'

import { getProductData } from './helper'

const useCustom = () => {
  const { getUserInformation } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverShown, setPopoverShown] = useState(false)

  const userInfo = getUserInformation()

  const productData = useMemo(
    () =>
      getProductData(
        userInfo?.userAccess?.roleType,
        userInfo?.userProd,
        PRODUCT,
      ),
    [userInfo],
  )

  const handleClose = useCallback(() => {
    setPopoverShown(false)
  }, [])

  const handlePopoverShown = useCallback(
    (value) => (e) => {
      if (productData.length > 0) {
        setPopoverShown(value)
        setAnchorEl(e.currentTarget)
      }
    },
    [productData],
  )

  return {
    data: {
      productData,
    },
    handler: {
      handleClose,
      handlePopoverShown,
    },
    state: {
      anchorEl,
      popoverShown,
    },
  }
}

export default useCustom
