import { generate as generateCode } from 'generate-password'

export const getInitials = (name, type = 'user') => {
  const names = name.split(' ')
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    if (type === 'user') {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    } else {
      names.forEach((splittedName, index) => {
        if (index > 0) {
          initials += splittedName.substring(0, 1).toUpperCase()
        }
      })
    }
  }

  return initials
}

export const generateCodeName = (name) => {
  const codeNum = generateCode({
    length: 3,
    numbers: true,
    lowercase: false,
    uppercase: false,
  })
  const codeWord = generateCode({
    length: 1,
    lowercase: false,
  })
  const initials = name.replace(/\s/g, '').toUpperCase().substr(0, 8)
  return `G-${initials}-${codeNum}${codeWord}` // G-XXXXXXXX-000A
}

const hasUpperCase = (string) => {
  const pattern = /^(.*[A-Z])/
  if (string && pattern.test(string)) {
    return true
  }
  return false
}

const hasLowerCase = (string) => {
  const pattern = /^(.*[a-z])/
  if (string && pattern.test(string)) {
    return true
  }
  return false
}

const hasNumber = (string) => {
  const pattern = /^(.*[0-9])/
  if (string && pattern.test(string)) {
    return true
  }
  return false
}

const hasSpecial = (string) => {
  const pattern = /^(.*[\W_])/
  if (string && pattern.test(string)) {
    return true
  }
  return false
}

const minLength = (string) => {
  const pattern = /^.{8,}/
  if (string && pattern.test(string)) {
    return true
  }
  return false
}

export const validatePass = (string) => ({
  hasUpperCase: hasUpperCase(string),
  hasLowerCase: hasLowerCase(string),
  hasNumber: hasNumber(string),
  hasSpecial: hasSpecial(string),
  minLength: minLength(string),
})

export const isHTML = (string) => /<\/?[a-z][\s\S]*>/i.test(string)

export const isUUID = (string) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    string,
  )

export const removeExtraSpace = (string) => string?.replace(/\s+/g, ' ').trim()

export const objToBase64 = (obj) =>
  Buffer.from(JSON.stringify(obj)).toString('base64')
