import React from 'react'

import { Box } from '@material-ui/core'
import { Button, Text } from '@xti/frontend-kit-components'
import { Field, Form, Formik } from 'formik'

import CustomTextField from '~/components/CustomTextField'
import { SCREEN_TYPE } from '~/config/screen'
import useCustom from '~/pages/ForgetPassword/hooks'
import forgetPasswordSchema from '~/pages/ForgetPassword/schema'

import useStyle from './style'

const ForgetPasswordDesktop = () => {
  const { handler } = useCustom({ screen: SCREEN_TYPE.DESKTOP })
  const classes = useStyle()

  return (
    <Box display="flex" height="100%" flex={1} className={classes.container}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={1}
        ml={8}
      >
        <Box
          display="flex"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          className={classes.formWrapper}
        >
          <img
            className={classes.logo}
            src="/public/images/xapiens-logo-black.svg"
            alt="landing"
          />
          <Box display="flex" flexDirection="column">
            <Text component="div" size="md" variant="paragraph" bold>
              Forgot Password
            </Text>
            <Box mb={5}>
              <Text component="span" size="sm" variant="paragraph">
                Enter your email address associated with your account and we’ll
                send you a link to reset your password
              </Text>
            </Box>
          </Box>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={forgetPasswordSchema}
            onSubmit={handler.handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={classes.formSection}
                  mt={8}
                >
                  <Field
                    component={CustomTextField}
                    label="Email"
                    labelPosition="vertical"
                    name="email"
                    placeholder="Email"
                    size="lg"
                    width="100%"
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    className={classes.buttonWrapper}
                    mt={8}
                  >
                    <Button
                      handleClick={handler.handleBack}
                      disableRipple
                      variant="secondary"
                      text="Back"
                      size="lg"
                    />
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      text="Send"
                      size="lg"
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={1}
        className={classes.background}
      />
    </Box>
  )
}

export default ForgetPasswordDesktop
