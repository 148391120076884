import React from 'react'

import { Box, Button as MUIButton } from '@material-ui/core'
import { Button, Text } from '@xti/frontend-kit-components'
import { Field, Formik, Form } from 'formik'

import CustomTextField from '~/components/CustomTextField'
import { SCREEN_TYPE } from '~/config/screen'
import useCustom from '~/pages/ForgetPassword/hooks'
import forgetPasswordSchema from '~/pages/ForgetPassword/schema'

import useStyle from './style'

const ForgetPasswordMobile = () => {
  const { handler } = useCustom({ screen: SCREEN_TYPE.MOBILE })
  const classes = useStyle()

  return (
    <Box
      display="flex"
      height="100%"
      flex={1}
      className={classes.container}
      flexDirection="column"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={1}
        pb={4}
      >
        <Box
          display="flex"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          className={classes.formWrapper}
        >
          <img
            className={classes.logo}
            src="/public/images/xapiens-logo-black.svg"
            alt="landing"
          />
          <Box display="flex" flexDirection="column">
            <Box mb={1}>
              <Text size="sm" variant="paragraph" bold>
                Forgot Password
              </Text>
            </Box>
            <Box width="100%">
              <Text size="x3s" variant="paragraph">
                Enter your email address associated with your account and we’ll
                send you a link to reset your password
              </Text>
            </Box>
          </Box>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={forgetPasswordSchema}
            onSubmit={handler.handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={classes.formSection}
                  mt={4}
                >
                  <Field
                    component={CustomTextField}
                    label="Email"
                    labelPosition="vertical"
                    name="email"
                    placeholder="Email"
                    size="sm"
                    width="100%"
                  />
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    text="Send"
                    size="md"
                    width="100%"
                  />
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    mt={7}
                  >
                    <MUIButton
                      className={classes.textLink}
                      onClick={handler.handleBack}
                    >
                      <Text size="xxs" variant="paragraph">
                        Back To Login
                      </Text>
                    </MUIButton>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  )
}

export default ForgetPasswordMobile
