import { makeStyles } from '@material-ui/styles'

import { colors } from '~/styles/theme'

const style = makeStyles((theme) => ({
  icons: {
    width: '21px',
    height: '25px',
    cursor: 'pointer',
  },
  userInfo: {
    cursor: 'pointer',
  },
  popoverWrapper: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  button: {
    textTransform: 'none',
    justifyContent: 'flex-start',
  },
  paper: {
    height: '100vh',
    maxHeight: '100vh',
    width: '260px',
    borderRadius: '0px',
  },
  separator: {
    display: 'flex',
    flex: 1,
    border: `1px solid ${colors.Concrete}`,
  },
}))

export default style
