module.exports = {
  authHost: `${process.env.AUTH_HOST}/query`,
  graphQLHost: process.env.GRAPHQL_HOST,
  graphQLSubscriptionHost: process.env.GRAPHQL_SUBSCRIPTION_HOST,
  idmProductKey: process.env.IDM_KEY,
  fleetoProductKey: process.env.PRODUCT_KEY_FLEETO,
  fleetoV2ProductKey: process.env.PRODUCT_KEY_FLEETOV2,
  fleetoProductURL: process.env.PRODUCT_URL_FLEETO,
  fleetoV2ProductURL: process.env.PRODUCT_URL_FLEETOV2,
  disableOtp: process.env.DISABLE_OTP,
}
