import { spacing } from '@xti/frontend-kit-components'

import {
  idmProductKey,
  fleetoProductKey,
  fleetoV2ProductKey,
  fleetoProductURL,
  fleetoV2ProductURL,
} from '~/config'

export const OTP_INPUT_DURATION = 300

export const NOT_SELECTED = 'not-selected'

export const UNAUTHORIZED = 'Unauthorized'

export const IDM_ID = idmProductKey

export const currentDomain = window.location.hostname.split('.')[1]

export const IDM_NAME = 'ZX Identity Management'

export const ROLE_KEYS = {
  CLIENT_ADMIN: 'CLIENT_ADMIN',
  SYS_ADMIN: 'SYSADMIN',
  USER: 'USER',
}

export const EXCLUDES_INVITE_USER_ROLES = [
  ROLE_KEYS.CLIENT_ADMIN,
  ROLE_KEYS.SYS_ADMIN,
]

export const ROLE_DESCRIPTION = {
  [ROLE_KEYS.CLIENT_ADMIN]: 'Grants access to application and administration',
  [ROLE_KEYS.SYS_ADMIN]: 'Grants access to application and administration',
}

export const DRAWER_MENU_KEYS = {
  ADMINISTRATOR: 'administrator',
  AUDIT_LOG: 'audit-log',
  ORGANIZATION: 'organization',
  ROLE: 'role',
  USER: 'user',
  GROUP: 'group',
  SETTING: 'settings',
  APP_SETTING: 'app-settings',
  LOG: 'log',
  POLICY: 'policy',
  NOTIFICATIONS: 'notifications',
}

export const DRAWER_MENU_CATEGORIES = {
  ACCESS: 'Site Access',
  MANAGEMENT: 'User Management',
  APP_SETTING: 'Application Settings',
  LOG: 'Log Monitoring',
  SETTING: 'Account Settings',
  NOTIFICATIONS: 'Notifications',
  REPORT: 'Report',
}

export const DRAWER_MENU_TYPES = {
  [DRAWER_MENU_KEYS.APP_SETTING]: 'category',
  [DRAWER_MENU_KEYS.LOG]: 'category',
  [DRAWER_MENU_KEYS.NOTIFICATIONS]: 'category',
  [DRAWER_MENU_KEYS.SETTING]: 'category',
}

export const SUBMENU_KEYS = {
  APP_SETTING_NOTIFICATIONS: `${DRAWER_MENU_KEYS.APP_SETTING}/notifications`,
  APP_SETTING_NOTIFICATIONS_EDIT: `${DRAWER_MENU_KEYS.APP_SETTING}/notifications/edit`,
  APP_SETTING_NOTIFICATIONS_LIST: `${DRAWER_MENU_KEYS.APP_SETTING}/notifications/list`,
  APP_SETTING_RECIPIENT: `${DRAWER_MENU_KEYS.APP_SETTING}/recipient`,
  APP_SETTING_RECIPIENT_EDIT: `${DRAWER_MENU_KEYS.APP_SETTING}/recipient/edit`,
  APP_SETTING_RECIPIENT_LIST: `${DRAWER_MENU_KEYS.APP_SETTING}/recipient/list`,
  GROUP_ADD: `${DRAWER_MENU_KEYS.GROUP}/add`,
  GROUP_ADD_MEMBER: `${DRAWER_MENU_KEYS.GROUP}/add-member`,
  GROUP_ADD_POLICY: `${DRAWER_MENU_KEYS.GROUP}/add-policy`,
  GROUP_ADD_PRODUCT_GRP: `${DRAWER_MENU_KEYS.GROUP}/add-product`,
  GROUP_DETAIL: `${DRAWER_MENU_KEYS.GROUP}/detail`,
  GROUP_LIST: `${DRAWER_MENU_KEYS.GROUP}/list`,
  LOG_NOTIFICATION: `${DRAWER_MENU_KEYS.LOG}/notification`,
  LOG_NOTIFICATION_LIST: `${DRAWER_MENU_KEYS.LOG}/notification/list`,
  NOTIFICATIONS_CENTER: `${DRAWER_MENU_KEYS.NOTIFICATIONS}/notification-center`,
  NOTIFICATIONS_SETTING: `${DRAWER_MENU_KEYS.NOTIFICATIONS}/notification-settings`,
  ORG_ADD: `${DRAWER_MENU_KEYS.ORGANIZATION}/add`,
  ORG_ADD_PRODUCT_ORG: `${DRAWER_MENU_KEYS.ORGANIZATION}/add-product`,
  ORG_DETAIL: `${DRAWER_MENU_KEYS.ORGANIZATION}/detail`,
  ORG_EDIT: `${DRAWER_MENU_KEYS.ORGANIZATION}/edit`,
  ORG_LIST: `${DRAWER_MENU_KEYS.ORGANIZATION}/list`,
  POLICY_EDIT: `${DRAWER_MENU_KEYS.POLICY}/edit`,
  POLICY_LIST: `${DRAWER_MENU_KEYS.POLICY}/list`,
  ROLE_ADD: `${DRAWER_MENU_KEYS.ROLE}/add`,
  ROLE_EDIT: `${DRAWER_MENU_KEYS.ROLE}/edit`,
  ROLE_LIST: `${DRAWER_MENU_KEYS.ROLE}/list`,
  SETTING_ADD_PHONE: `${DRAWER_MENU_KEYS.SETTING}/add-phone`,
  SETTING_EMAIL: `${DRAWER_MENU_KEYS.SETTING}/email`,
  SETTING_PASSWORD: `${DRAWER_MENU_KEYS.SETTING}/password`,
  SETTING_PROFILE: `${DRAWER_MENU_KEYS.SETTING}/profile`,
  USER_ADD_POLICY: `${DRAWER_MENU_KEYS.USER}/add-policy`,
  USER_ADD_TO_GROUP: `${DRAWER_MENU_KEYS.USER}/add-to-group`,
  USER_INVITE_CLIENT_ADMIN: `${DRAWER_MENU_KEYS.USER}/invite-client-admin`,
  USER_INVITE_USER: `${DRAWER_MENU_KEYS.USER}/invite-user`,
  USER_LIST: `${DRAWER_MENU_KEYS.USER}/list`,
  USER_PROFILE: `${DRAWER_MENU_KEYS.USER}/profile`,
}

export const ACCOUNT_SETTINGS_SUBMENUS = [
  // ? : Turn On this Code, if email feature is already done
  // SUBMENU_KEYS.SETTING_EMAIL,
  SUBMENU_KEYS.SETTING_ADD_PHONE,
  SUBMENU_KEYS.SETTING_PASSWORD,
  SUBMENU_KEYS.SETTING_PROFILE,
]

export const APP_SETTING_SUBMENUS = [
  SUBMENU_KEYS.APP_SETTING_NOTIFICATIONS,
  SUBMENU_KEYS.APP_SETTING_NOTIFICATIONS_EDIT,
  SUBMENU_KEYS.APP_SETTING_NOTIFICATIONS_LIST,
  SUBMENU_KEYS.APP_SETTING_RECIPIENT,
  SUBMENU_KEYS.APP_SETTING_RECIPIENT_EDIT,
  SUBMENU_KEYS.APP_SETTING_RECIPIENT_LIST,
]

export const GROUP_SUBMENUS = [
  SUBMENU_KEYS.GROUP_ADD,
  SUBMENU_KEYS.GROUP_ADD_MEMBER,
  SUBMENU_KEYS.GROUP_ADD_POLICY,
  SUBMENU_KEYS.GROUP_ADD_PRODUCT_GRP,
  SUBMENU_KEYS.GROUP_DETAIL,
  SUBMENU_KEYS.GROUP_LIST,
]

export const LOG_SUBMENUS = [
  SUBMENU_KEYS.LOG_NOTIFICATION,
  SUBMENU_KEYS.LOG_NOTIFICATION_LIST,
]

export const NOTIFICATIONS_SUBMENUS = [
  SUBMENU_KEYS.NOTIFICATIONS_CENTER,
  SUBMENU_KEYS.NOTIFICATIONS_SETTING,
]

export const ORGANIZATION_SUBMENUS = [
  SUBMENU_KEYS.ORG_ADD,
  SUBMENU_KEYS.ORG_ADD_PRODUCT_ORG,
  SUBMENU_KEYS.ORG_DETAIL,
  SUBMENU_KEYS.ORG_EDIT,
  SUBMENU_KEYS.ORG_LIST,
]

export const POLICY_SUBMENUS = [
  SUBMENU_KEYS.POLICY_EDIT,
  SUBMENU_KEYS.POLICY_LIST,
]

export const ROLE_SUBMENUS = [
  SUBMENU_KEYS.ROLE_ADD,
  SUBMENU_KEYS.ROLE_EDIT,
  SUBMENU_KEYS.ROLE_LIST,
]

export const USER_SUBMENUS = [
  SUBMENU_KEYS.USER_ADD_POLICY,
  SUBMENU_KEYS.USER_ADD_TO_GROUP,
  SUBMENU_KEYS.USER_INVITE_CLIENT_ADMIN,
  SUBMENU_KEYS.USER_INVITE_USER,
  SUBMENU_KEYS.USER_LIST,
  SUBMENU_KEYS.USER_PROFILE,
]

export const ADMINISTRATOR_MENUS = [
  ...APP_SETTING_SUBMENUS,
  ...GROUP_SUBMENUS,
  ...LOG_SUBMENUS,
  ...ORGANIZATION_SUBMENUS,
  ...POLICY_SUBMENUS,
  ...ROLE_SUBMENUS,
  ...USER_SUBMENUS,
  DRAWER_MENU_KEYS.ADMINISTRATOR,
  DRAWER_MENU_KEYS.APP_SETTING,
  DRAWER_MENU_KEYS.GROUP,
  DRAWER_MENU_KEYS.LOG,
  DRAWER_MENU_KEYS.ORGANIZATION,
  DRAWER_MENU_KEYS.POLICY,
  DRAWER_MENU_KEYS.ROLE,
  DRAWER_MENU_KEYS.USER,
]

export const AUDIT_LOG_MENUS = [DRAWER_MENU_KEYS.AUDIT_LOG]

export const USER_MENUS = [
  ...ACCOUNT_SETTINGS_SUBMENUS,
  ...NOTIFICATIONS_SUBMENUS,
  DRAWER_MENU_KEYS.NOTIFICATIONS,
  DRAWER_MENU_KEYS.SETTING,
]

export const MENUS = [...ADMINISTRATOR_MENUS, ...AUDIT_LOG_MENUS, ...USER_MENUS]

export const SYSADMIN_DRAWER_MENUS = [
  DRAWER_MENU_KEYS.ORGANIZATION,
  DRAWER_MENU_KEYS.POLICY,
  DRAWER_MENU_KEYS.USER,
  SUBMENU_KEYS.NOTIFICATIONS_CENTER,
  SUBMENU_KEYS.SETTING_PROFILE,
]

export const CLIENTADMIN_DRAWER_MENUS = [
  ...APP_SETTING_SUBMENUS,
  ...LOG_SUBMENUS,
  ...NOTIFICATIONS_SUBMENUS,
  DRAWER_MENU_KEYS.AUDIT_LOG,
  DRAWER_MENU_KEYS.GROUP,
  DRAWER_MENU_KEYS.ORGANIZATION,
  DRAWER_MENU_KEYS.ROLE,
  DRAWER_MENU_KEYS.USER,
  SUBMENU_KEYS.SETTING_PASSWORD,
  SUBMENU_KEYS.SETTING_PROFILE,
]

export const USER_DRAWER_MENUS = [
  ...ACCOUNT_SETTINGS_SUBMENUS,
  ...NOTIFICATIONS_SUBMENUS,
]

export const SYSADMIN_MENUS = [
  ...SYSADMIN_DRAWER_MENUS,
  DRAWER_MENU_KEYS.ADMINISTRATOR,
  DRAWER_MENU_KEYS.SETTING,
  DRAWER_MENU_KEYS.NOTIFICATIONS,
  SUBMENU_KEYS.ORG_ADD,
  SUBMENU_KEYS.ORG_ADD_PRODUCT_ORG,
  SUBMENU_KEYS.ORG_DETAIL,
  SUBMENU_KEYS.ORG_EDIT,
  SUBMENU_KEYS.ORG_LIST,
  SUBMENU_KEYS.POLICY_EDIT,
  SUBMENU_KEYS.POLICY_LIST,
  SUBMENU_KEYS.SETTING_ADD_PHONE,
  SUBMENU_KEYS.SETTING_PROFILE,
  SUBMENU_KEYS.USER_ADD_POLICY,
  SUBMENU_KEYS.USER_INVITE_CLIENT_ADMIN,
  SUBMENU_KEYS.USER_LIST,
  SUBMENU_KEYS.USER_PROFILE,
]

export const CLIENTADMIN_MENUS = [
  ...CLIENTADMIN_DRAWER_MENUS,
  ...GROUP_SUBMENUS,
  DRAWER_MENU_KEYS.ADMINISTRATOR,
  DRAWER_MENU_KEYS.APP_SETTING,
  DRAWER_MENU_KEYS.LOG,
  DRAWER_MENU_KEYS.SETTING,
  DRAWER_MENU_KEYS.NOTIFICATIONS,
  SUBMENU_KEYS.ORG_DETAIL,
  SUBMENU_KEYS.ORG_EDIT,
  SUBMENU_KEYS.ROLE_ADD,
  SUBMENU_KEYS.ROLE_EDIT,
  SUBMENU_KEYS.ROLE_LIST,
  SUBMENU_KEYS.SETTING_ADD_PHONE,
  SUBMENU_KEYS.SETTING_PASSWORD,
  SUBMENU_KEYS.SETTING_PROFILE,
  SUBMENU_KEYS.USER_ADD_POLICY,
  SUBMENU_KEYS.USER_ADD_TO_GROUP,
  SUBMENU_KEYS.USER_INVITE_USER,
  SUBMENU_KEYS.USER_LIST,
  SUBMENU_KEYS.USER_PROFILE,
]

export const USER_BUTTON_LABEL = {
  [ROLE_KEYS.SYS_ADMIN]: 'Invite Client Admin',
  [ROLE_KEYS.CLIENT_ADMIN]: 'Invite User',
}

export const ROLE_DRAWER_MENU_MAPPINGS = {
  [ROLE_KEYS.SYS_ADMIN]: SYSADMIN_DRAWER_MENUS,
  [ROLE_KEYS.CLIENT_ADMIN]: CLIENTADMIN_DRAWER_MENUS,
  [ROLE_KEYS.USER]: USER_DRAWER_MENUS,
}

export const ROLE_MENU_MAPPINGS = {
  [ROLE_KEYS.SYS_ADMIN]: SYSADMIN_MENUS,
  [ROLE_KEYS.CLIENT_ADMIN]: CLIENTADMIN_MENUS,
}

export const PERMISSIONS = {
  READ_ATTR_POLICY: 'READ_ATTR_POLICY',
  READ_NOTIF_GROUP: 'READ_NOTIF_GROUP',
  READ_NOTIF_LOG: 'READ_NOTIF_LOG',
  READ_NOTIF_PERMISSION: 'READ_NOTIF_PERMISSION',
  READ_ORGANIZATION: 'READ_ORGANIZATION',
  READ_PERMISSION: 'READ_PERMISSION',
  READ_PRODUCT: 'READ_PRODUCT',
  READ_PRODUCT_ORGANIZATION: 'READ_PRODUCT_ORGANIZATION',
  READ_ROLE: 'READ_ROLE',
  READ_ROLE_PERMISSION: 'READ_ROLE_PERMISSION',
  READ_USER: 'READ_USER',
  WRITE_ATTR_POLICY: 'WRITE_ATTR_POLICY',
  WRITE_NOTIF_GROUP: 'WRITE_NOTIF_GROUP',
  WRITE_NOTIF_PERMISSION: 'WRITE_NOTIF_PERMISSION',
  WRITE_ORGANIZATION: 'WRITE_ORGANIZATION',
  WRITE_PERMISSION: 'WRITE_PERMISSION',
  WRITE_PRODUCT: 'WRITE_PRODUCT',
  WRITE_PRODUCT_ORGANIZATION: 'WRITE_PRODUCT_ORGANIZATION',
  WRITE_ROLE: 'WRITE_ROLE',
  WRITE_ROLE_PERMISSION: 'WRITE_ROLE_PERMISSION',
  WRITE_USER: 'WRITE_USER',
}

export const PERMISSION_MAPPINGS = {
  [DRAWER_MENU_KEYS.APP_SETTING]: PERMISSIONS.READ_NOTIF_PERMISSION,
  [DRAWER_MENU_KEYS.GROUP]: PERMISSIONS.READ_ORGANIZATION,
  [DRAWER_MENU_KEYS.LOG]: PERMISSIONS.READ_NOTIF_LOG,
  [DRAWER_MENU_KEYS.ORGANIZATION]: PERMISSIONS.READ_ORGANIZATION,
  [DRAWER_MENU_KEYS.POLICY]: PERMISSIONS.READ_ATTR_POLICY,
  [DRAWER_MENU_KEYS.ROLE]: PERMISSIONS.READ_ROLE,
  [DRAWER_MENU_KEYS.USER]: PERMISSIONS.READ_USER,
  [SUBMENU_KEYS.APP_SETTING_NOTIFICATIONS]: PERMISSIONS.READ_NOTIF_PERMISSION,
  [SUBMENU_KEYS.APP_SETTING_NOTIFICATIONS_EDIT]:
    PERMISSIONS.WRITE_NOTIF_PERMISSION,
  [SUBMENU_KEYS.APP_SETTING_NOTIFICATIONS_LIST]:
    PERMISSIONS.READ_NOTIF_PERMISSION,
  [SUBMENU_KEYS.APP_SETTING_RECIPIENT]: PERMISSIONS.READ_NOTIF_GROUP,
  [SUBMENU_KEYS.APP_SETTING_RECIPIENT_EDIT]: PERMISSIONS.WRITE_NOTIF_GROUP,
  [SUBMENU_KEYS.APP_SETTING_RECIPIENT_LIST]: PERMISSIONS.READ_NOTIF_GROUP,
  [SUBMENU_KEYS.GROUP_ADD]: PERMISSIONS.WRITE_ORGANIZATION,
  [SUBMENU_KEYS.GROUP_ADD_MEMBER]: PERMISSIONS.WRITE_ORGANIZATION,
  [SUBMENU_KEYS.GROUP_ADD_POLICY]: PERMISSIONS.WRITE_ATTR_POLICY,
  [SUBMENU_KEYS.GROUP_ADD_PRODUCT_GRP]: PERMISSIONS.WRITE_PRODUCT_ORGANIZATION,
  [SUBMENU_KEYS.GROUP_DETAIL]: PERMISSIONS.READ_ORGANIZATION,
  [SUBMENU_KEYS.GROUP_LIST]: PERMISSIONS.READ_ORGANIZATION,
  [SUBMENU_KEYS.LOG_NOTIFICATION]: PERMISSIONS.READ_NOTIF_LOG,
  [SUBMENU_KEYS.LOG_NOTIFICATION_LIST]: PERMISSIONS.READ_NOTIF_LOG,
  [SUBMENU_KEYS.ORG_ADD]: PERMISSIONS.WRITE_ORGANIZATION,
  [SUBMENU_KEYS.ORG_ADD_PRODUCT_ORG]: PERMISSIONS.WRITE_PRODUCT_ORGANIZATION,
  [SUBMENU_KEYS.ORG_DETAIL]: PERMISSIONS.READ_ORGANIZATION,
  [SUBMENU_KEYS.ORG_EDIT]: PERMISSIONS.WRITE_ORGANIZATION,
  [SUBMENU_KEYS.ORG_LIST]: PERMISSIONS.READ_ORGANIZATION,
  [SUBMENU_KEYS.POLICY_EDIT]: PERMISSIONS.WRITE_ATTR_POLICY,
  [SUBMENU_KEYS.POLICY_LIST]: PERMISSIONS.READ_ATTR_POLICY,
  [SUBMENU_KEYS.ROLE_ADD]: PERMISSIONS.WRITE_ROLE,
  [SUBMENU_KEYS.ROLE_EDIT]: PERMISSIONS.WRITE_ROLE,
  [SUBMENU_KEYS.ROLE_LIST]: PERMISSIONS.READ_ROLE,
  [SUBMENU_KEYS.USER_ADD_POLICY]: PERMISSIONS.WRITE_ATTR_POLICY,
  [SUBMENU_KEYS.USER_ADD_TO_GROUP]: PERMISSIONS.WRITE_USER,
  [SUBMENU_KEYS.USER_INVITE_CLIENT_ADMIN]: PERMISSIONS.WRITE_USER,
  [SUBMENU_KEYS.USER_INVITE_USER]: PERMISSIONS.WRITE_USER,
  [SUBMENU_KEYS.USER_LIST]: PERMISSIONS.READ_USER,
  [SUBMENU_KEYS.USER_PROFILE]: PERMISSIONS.READ_USER,
}

export const SEARCH_FIELDNAME = {
  ID: 'ID',
  NAME: 'NAME',
  PID: 'PID',
  ORG_ID: 'ORG_ID',
  ROLE_ID: 'ROLE_ID',
  PROD_ID: 'PROD_ID',
}

export const SEARCH_OPERATOR = {
  EQUAL: 'EQUAL',
  LESS_THAN: 'LESS',
  GREATER: 'GREATER',
  LESS_AND_EQUAL: 'LESSEQUAL',
  GREATER_AND_EQUAL: 'GREATEREQUAL',
  LIKE: 'LIKE',
}

export const PRODUCT_KEY = {
  FLEETO: fleetoProductKey,
  FLEETOV2: fleetoV2ProductKey,
}

export const PRODUCT = [PRODUCT_KEY.FLEETO]

export const PRODUCT_ALT = {
  [PRODUCT_KEY.FLEETO]: 'fleeto',
}

export const DOMAINS = {
  FLEETO_MAIN_DOMAIN: fleetoProductURL
    ?.split('.')?.[1]
    ?.replace(/^https?:\/\//, ''),
  FLEETO_ALVA_DOMAIN: fleetoV2ProductURL
    ?.split('.')?.[1]
    ?.replace(/^https?:\/\//, ''),
}

export const productURLMapping = {
  [DOMAINS.FLEETO_MAIN_DOMAIN]: fleetoProductURL,
  [DOMAINS.FLEETO_ALVA_DOMAIN]: fleetoV2ProductURL,
}

export const PRODUCT_URL = {
  [PRODUCT_KEY.FLEETO]: productURLMapping[currentDomain] || fleetoProductURL,
}

export const PRODUCT_NAME = {
  [PRODUCT_KEY.FLEETO]: 'Fleeto',
}

export const AUTH_PROVIDER_TYPE = {
  internal: 'Internal',
  external: 'External',
}

export const AUTH_PROVIDER_ID_MAPPING = {
  '1': 'Internal',
  '2': 'Microsoft',
  '3': 'Google',
}

export const AUTH_PROVIDER_TYPE_MAPPING = {
  '1': AUTH_PROVIDER_TYPE.internal,
  '2': AUTH_PROVIDER_TYPE.external,
  '3': AUTH_PROVIDER_TYPE.external,
}

export const DEFAULT_FORM_STYLING = {
  formWrapper: {
    borderRadius: '8px',
  },
  formSection: {
    '& > div:not(:last-child)': {
      marginBottom: spacing[6],
    },
  },
}

export const ALLOWED_DOMAINS = ['xtrip.link', 'xapiens.id', 'alvaauto.com']
