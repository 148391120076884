import { useRef, useEffect } from 'react'

export const useInterval = (fn, delay) => {
  const savedFn = useRef()

  useEffect(() => {
    savedFn.current = fn
  }, [fn])

  useEffect(() => {
    if (delay > 0) {
      const id = setInterval(() => savedFn.current(), delay)
      return () => clearInterval(id)
    }

    return null
  }, [delay])
}

export const customDelay = (ms) => new Promise((res) => setTimeout(res, ms))

export default [useInterval, customDelay]
