import React from 'react'
import { withRouter } from 'react-router-dom'

import { Box, Typography } from '@material-ui/core'
import { ProductSwitch } from '@xti/frontend-kit-components'

import useCustom from './hooks'
import useStyle from './style'

const ProductPopover = () => {
  const classes = useStyle()
  const { data, handler, state } = useCustom()
  return (
    data.productData.length > 0 && (
      <Box display="flex" justifyContent="flex-start">
        <Box
          data-testid="product-popover"
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handler.handlePopoverShown(!state.popoverShown)}
          role="button"
        >
          <Typography variant="h6" className={classes.titleLink}>
            <img
              className={classes.logo}
              src="/public/icons/product-menu.svg"
              alt="product-menu-icon"
            />
          </Typography>
        </Box>
        <ProductSwitch
          open={state.popoverShown}
          anchorEl={state.anchorEl}
          handleClose={handler.handleClose}
          data={data.productData}
        />
      </Box>
    )
  )
}

export default withRouter(ProductPopover)
