import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Box } from '@material-ui/core'
import { NotAuthorized, NotFound } from '@xti/frontend-kit-components'
import classNames from 'classnames'

import DrawerMenu from '~/components/DrawerMenu'
import NotDisplayWell from '~/components/NotDisplayWell'
import { ROLE_KEYS } from '~/config/constants'
import ChooseDashboardPage from '~/pages/ChooseDashboard'
import MyAccount from '~/pages/MyAccount'
import routes from '~/routes'

import useStyle from './style'

const UserLayout = ({ isLandingPage, isMobile, roleType }) => {
  const mapRouteByRole = {
    [ROLE_KEYS.CLIENT_ADMIN]: 'clientAdminAccess',
    [ROLE_KEYS.SYS_ADMIN]: 'sysAdminAccess',
    [ROLE_KEYS.USER]: 'userAccess',
  }
  let userRoutes = routes.filter(
    (route) => route?.userAccess && !route.disabled,
  )
  if (isMobile) {
    userRoutes = userRoutes.filter(
      (route) => route?.[mapRouteByRole[roleType]] && !route.disabled,
    )
  }
  const classes = useStyle()
  const NotFoundMobile = () => <NotFound imgWidth="90vw" />
  const NotAuthorizedMobile = () => <NotAuthorized imageSize="90vw" />

  return (
    <Box className={classes.container} display="flex" height="100%" flex={1}>
      {!isLandingPage && !isMobile && <DrawerMenu />}
      <Box
        className={classNames(classes.content, {
          [classes.contentShift]: true,
        })}
        display="flex"
        flexDirection="column"
        height="100%"
        flex={1}
        p={isMobile ? undefined : 3}
      >
        {!isMobile && (
          <Box
            className={classes.contentHeader}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          />
        )}
        <Switch>
          {userRoutes.map(({ path, exact, component: Component }) => (
            <Route
              key={path}
              path={path}
              component={() => <Component drawerOpen />}
              exact={!!exact}
            />
          ))}
          <Route
            exact
            path="/"
            component={isMobile ? MyAccount : ChooseDashboardPage}
          />
          <Route exact path="/settings">
            <Redirect to="/settings/profile" />
          </Route>
          <Route exact path="/notifications">
            <Redirect to="/notifications/notification-center" />
          </Route>
          <Route
            exact
            path="/not-authorized"
            component={isMobile ? NotAuthorizedMobile : NotAuthorized}
          />
          <Route
            exact
            path="/not-found"
            component={isMobile ? NotFoundMobile : NotFound}
          />
          {isMobile && <Route component={NotDisplayWell} />}
        </Switch>
      </Box>
    </Box>
  )
}

UserLayout.defaultProps = {
  isLandingPage: false,
  isMobile: false,
  roleType: '',
}

UserLayout.propTypes = {
  isLandingPage: PropTypes.bool,
  isMobile: PropTypes.bool,
  roleType: PropTypes.string,
}

export default UserLayout
