import { useCallback, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { DRAWER_MENU_CATEGORIES } from '~/config/constants'
import useAuth from '~/utils/auth'

const useCustom = () => {
  const history = useHistory()
  const {
    getUserInformation,
    getDrawerRoutesByMenus,
    getMenusByRoleName,
    handleLogout,
  } = useAuth()
  const user = getUserInformation()

  const [popoverShown, setPopoverShown] = useState(false)
  const [showSettingMenu, setShowSettingMenu] = useState(false)

  const menus = useMemo(() => getMenusByRoleName(user), [user])
  const drawerRoutes = useMemo(() => getDrawerRoutesByMenus(menus), [menus])

  const settingRoutes = useMemo(
    () =>
      drawerRoutes.filter(
        (route) => route.category === DRAWER_MENU_CATEGORIES.SETTING,
      ),
    [drawerRoutes],
  )

  const handleClose = () => {
    setPopoverShown(false)
  }

  const handleRoute = useCallback((value = '') => {
    history.push({
      pathname: `/${value}`,
    })
  }, [])

  const handlePopoverShown = (value) => () => {
    setPopoverShown(value)
  }

  const handleSettingMenuShown = useCallback((value) => {
    setShowSettingMenu(value)
  }, [])

  return {
    handler: {
      handleClose,
      handlePopoverShown,
      handleRoute,
      handleSettingMenuShown,
      handleLogout,
    },
    state: {
      popoverShown,
      showSettingMenu,
    },
    data: {
      profileInfo: user,
      settingRoutes,
    },
  }
}

export default useCustom
