import PropTypes from 'prop-types'
import React from 'react'

import { Box, Paper, Popper } from '@material-ui/core'
import { FiberManualRecord as CircleIcon } from '@material-ui/icons'
import { Text } from '@xti/frontend-kit-components'
import { black } from '@xti/frontend-kit-components/colors'

import { colors } from '~/styles/theme'

import useStyle from './style'

const PasswordHint = ({
  anchorEl,
  character,
  lowerCase,
  number,
  open,
  special,
  upperCase,
}) => {
  const classes = useStyle()
  return (
    <Popper
      className={classes.wrapper}
      open={open}
      anchorEl={anchorEl}
      placement="right"
      disablePortal={false}
      modifiers={{
        flip: {
          enabled: false,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
        },
        offset: {
          enabled: true,
          offset: '0, 20',
        },
      }}
    >
      <Paper className={classes.paper} data-popper-arrow>
        <Box p={6}>
          <Box display="flex" flexDirection="row" mb={3}>
            <Text component="div" size="sm" variant="paragraph" bold>
              Password Must
            </Text>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <CircleIcon
              htmlColor={number ? colors.Mantis : black[300]}
              classes={{ root: classes.icon }}
            />
            <Text component="div" size="sm" variant="paragraph">
              Have One Number
            </Text>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <CircleIcon
              htmlColor={special ? colors.Mantis : black[300]}
              classes={{ root: classes.icon }}
            />
            <Text component="div" size="sm" variant="paragraph">
              Have One Special Character
            </Text>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <CircleIcon
              htmlColor={upperCase ? colors.Mantis : black[300]}
              classes={{ root: classes.icon }}
            />
            <Text component="div" size="sm" variant="paragraph">
              Have One uppercase character
            </Text>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <CircleIcon
              htmlColor={lowerCase ? colors.Mantis : black[300]}
              classes={{ root: classes.icon }}
            />
            <Text component="div" size="sm" variant="paragraph">
              Have One lowercase character
            </Text>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <CircleIcon
              htmlColor={character ? colors.Mantis : black[300]}
              classes={{ root: classes.icon }}
            />
            <Text component="div" size="sm" variant="paragraph">
              Have 8 characters minimum
            </Text>
          </Box>
        </Box>
      </Paper>
    </Popper>
  )
}

PasswordHint.defaultProps = {
  anchorEl: null,
  character: false,
  lowerCase: false,
  number: false,
  open: false,
  special: false,
  upperCase: false,
}

PasswordHint.propTypes = {
  anchorEl: PropTypes.shape({}),
  character: PropTypes.bool,
  lowerCase: PropTypes.bool,
  number: PropTypes.bool,
  open: PropTypes.bool,
  special: PropTypes.bool,
  upperCase: PropTypes.bool,
}

export default PasswordHint
