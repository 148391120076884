import { useCallback, useEffect, useState } from 'react'

import { cookies as zxAuthCookies } from '@xti/frontend-kit-auth/auth'
import { useSearchQuery } from '@xti/frontend-kit-components'

import { PRODUCT_URL } from '~/config/constants'
import useAuth from '~/utils/auth'
import useServiceWorker from '~/utils/service-worker'

export const useRedirectSearchQueries = () => {
  const searchQuery = useSearchQuery()

  return {
    productId: searchQuery.productId || '',
  }
}

const useCustom = () => {
  const { isLoggedIn, getUserInformation, loadUserProfileData } = useAuth()
  const { isUpdateAvailable, updateApp } = useServiceWorker()
  const [isDialogOpen, setIsDialogOpen] = useState(isUpdateAvailable)
  const { token } = zxAuthCookies.getToken()
  const search = useRedirectSearchQueries()

  const profileInfo = getUserInformation()

  useEffect(() => {
    setIsDialogOpen(isUpdateAvailable)
  }, [isUpdateAvailable])

  const handleUpdateApp = useCallback(() => {
    updateApp()
  }, [])

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false)
  }, [])

  useEffect(() => {
    // trigger when login (initial state token, and token changes)
    if (token) {
      loadUserProfileData()
    }
  }, [token])

  useEffect(() => {
    // ? redirection feature
    if (
      token &&
      PRODUCT_URL[search?.productId] &&
      profileInfo?.mustChangePwd === false
    ) {
      window.location.assign(PRODUCT_URL[search?.productId])
    }
  }, [token, profileInfo])

  return {
    data: {
      profileInfo,
      token,
    },
    handler: {
      handleCloseDialog,
      handleUpdateApp,
    },
    state: {
      isDialogOpen,
      isLoggedIn,
    },
  }
}

export default useCustom
