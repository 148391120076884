import { makeStyles } from '@material-ui/styles'

const style = makeStyles((theme) => ({
  icons: {
    width: '21px',
    height: '25px',
    cursor: 'pointer',
  },
  userInfo: {
    cursor: 'pointer',
  },
  popoverWrapper: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  button: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    width: '217px',
  },
}))

export default style
