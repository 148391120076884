import PropTypes from 'prop-types'
import React, { Suspense } from 'react'
import { useLocation } from 'react-router-dom'

import { Box } from '@material-ui/core'
import { Loading } from '@xti/frontend-kit-components'

import Header from '~/components/Header'
import HeaderMobile from '~/components/HeaderMobile'
import InternalServerError from '~/components/InternalServerError'
import { ROLE_KEYS } from '~/config/constants'

import AdminLayout from './AdminLayout'
import useCustom from './hooks'
import useStyle from './style'
import UserLayout from './UserLayout'

const ProtectedLayout = ({ isMobile }) => {
  const classes = useStyle()
  const { data, state } = useCustom()
  const location = useLocation()

  const userRoleType = data.user?.userAccess?.roleType
  const userOrgId = data.user?.userOrg?.orgID

  const renderRoleLayout = () => {
    if (userRoleType === ROLE_KEYS.USER || isMobile) {
      return (
        <UserLayout
          isLandingPage={state.isLandingPage}
          roleType={userRoleType}
          isMobile={isMobile}
        />
      )
    }
    if (
      (userRoleType === ROLE_KEYS.CLIENT_ADMIN ||
        userRoleType === ROLE_KEYS.SYS_ADMIN) &&
      !isMobile
    ) {
      return (
        <AdminLayout
          isLandingPage={state.isLandingPage}
          orgID={userOrgId}
          roleType={userRoleType}
        />
      )
    }
    return null
  }

  return (
    <>
      {isMobile ? (
        <HeaderMobile location={location} />
      ) : (
        <Header location={location} />
      )}
      <InternalServerError>
        <Suspense
          fallback={
            <>
              <Box
                className={classes.contentHeader}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              />
              <Loading />
            </>
          }
        >
          {renderRoleLayout()}
        </Suspense>
      </InternalServerError>
    </>
  )
}

ProtectedLayout.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default ProtectedLayout
