import React from 'react'
import { withRouter } from 'react-router-dom'

import { Box, Button, Fade, Popover, Popper } from '@material-ui/core'
import {
  Error as ErrorIcon,
  ExitToApp as LogoutIcon,
  SettingsOutlined as SettingsIcon,
} from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { Text } from '@xti/frontend-kit-components'
import { palette } from '@xti/frontend-kit-components/colors'
import { startCase } from 'lodash-es'

import ProfileInfo from '../ProfileInfo'

import useCustom from './hooks'
import useStyle from './style'

const LogoutPopover = () => {
  const classes = useStyle()
  const { data, state, handler } = useCustom()

  return (
    <Box display="flex" justifyContent="flex-end" pr={3}>
      <Box
        data-testid="profile-popover"
        className={classes.userInfo}
        display="flex"
        alignItems="center"
        mr={3}
        onClick={handler.handlePopoverShown(!state.popoverShown)}
      >
        <ProfileInfo
          name={data?.profileInfo?.userName}
          nameSize="xs"
          description={data?.profileInfo?.userAccess?.roleName}
        />
        {state.warnPhoneNumber && (
          <Box
            onMouseEnter={handler.handleOpenPopper(true)}
            onMouseLeave={handler.handleOpenPopper(false)}
            ml={3}
          >
            <ErrorIcon fontSize="small" style={{ color: palette.Primary }} />
          </Box>
        )}
      </Box>
      <Popper
        open={state.openPopper}
        anchorEl={state.anchorElPopper}
        placement="bottom"
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
        disablePortal
        transition
      >
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Fade {...TransitionProps} timeout={350}>
            <Box m={4} boxShadow={1}>
              <Alert variant="standard" severity="error" data-testid="error">
                <Text size="xs" variant="paragraph">
                  Your profile is incomplete
                </Text>
              </Alert>
            </Box>
          </Fade>
        )}
      </Popper>
      <Popover
        id={state.popoverShown ? 'simple-popover' : undefined}
        open={state.popoverShown}
        anchorEl={state.anchorEl}
        onClose={handler.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          p={3}
          className={classes.popoverWrapper}
        >
          {data?.settingRoutes.length > 0 && (
            <Button
              data-testid="settings-popover-btn"
              className={classes.button}
              onClick={() =>
                handler.handleSettingMenuShown(!state.showSettingMenu)
              }
            >
              <SettingsIcon
                fontSize="small"
                style={{ color: palette.HavelockBlue }}
              />
              <Box ml={2}>
                <Text component="div" size="xs" variant="paragraph">
                  Settings
                </Text>
              </Box>
            </Button>
          )}
          {state.showSettingMenu &&
            data.settingRoutes.map((route) => (
              <Button
                className={classes.button}
                onClick={() =>
                  handler.handleSettingChild(`${route.parentId}/${route.id}`)
                }
                key={`button-${route.id}`}
                data-testid={`${route.id}-settings-popover-btn`}
              >
                <Box ml={8}>
                  <Text component="div" size="xs" variant="paragraph">
                    {startCase(route.id)}
                  </Text>
                </Box>
              </Button>
            ))}
          <Button
            data-testid="logout-popover-btn"
            className={classes.button}
            onClick={handler.handleLogout}
          >
            <LogoutIcon
              fontSize="small"
              style={{ color: palette.HavelockBlue }}
            />
            <Box ml={2}>
              <Text component="div" size="xs" variant="paragraph">
                Logout
              </Text>
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

export default withRouter(LogoutPopover)
