import { makeStyles } from '@material-ui/styles'

import { TABLET } from '~/config/screen'

const style = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    [`@media ${TABLET.MAX_WIDTH}`]: {
      height: 'auto',
      width: '100%',
    },
  },
  logo: {
    width: '140px',
    marginBottom: theme.spacing(5),
  },
  formWrapper: {
    flexBasis: '75%',
    [`@media ${TABLET.MAX_WIDTH}`]: {
      height: 'auto',
      width: '100%',
    },
  },
  background: {
    background: "url('/public/images/background.png')",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '15px',
    margin: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  formSection: {
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(6),
    },
  },
  buttonWrapper: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  checkBoxWrapper: {
    '& > *': {
      flexBasis: '50%',
    },
  },
  forgotPassword: {
    textTransform: 'none',
  },
}))

export default style
