import 'react-dates/lib/css/_datepicker.css'

import { withStyles } from '@material-ui/core/styles'
import { black } from '@xti/frontend-kit-components/colors'

const GlobalStyle = withStyles(() => {
  const fontFamily = 'Poppins, sans-serif'
  return {
    '@global': {
      '*': {
        fontFamily,
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
          backgroundColor: 'rgba(0,0,0,0)',
          '-webkit-border-radius': '100px',
        },
        '&::-webkit-scrollbar:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.09)',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(0,0,0,0.5)',
          '-webkit-border-radius': '100px',
        },
        '&::-webkit-scrollbar-thumb:active': {
          background: 'rgba(0,0,0,0.61)',
          '-webkit-border-radius': '100px',
        },
        '&::-webkit-scrollbar-thumb:vertical': {
          minHeight: '10px',
        },
        '&::-webkit-scrollbar-thumb:horizontal': {
          minWidth: '10px',
        },
        '&::-webkit-scrollbar-corner': {
          background: 'rgba(0, 0, 0, 0)',
        },
      },
      html: {
        height: '100%',
        fontSize: '16px',
      },
      body: {
        height: '100vh',
        margin: 0,
        padding: 0,
        fontFamily,
        backgroundColor: black[100],
      },
      '#root': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      'body.fontLoaded': {
        fontFamily,
      },
      p: {
        marginBlockEnd: 0,
        marginBlockStart: 0,
      },
    },
  }
})(() => null)

export default GlobalStyle
