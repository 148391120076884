import React from 'react'

import { useMediaQuery } from '@material-ui/core'

import { SCREEN_TYPE, MOBILE } from '~/config/screen'

import LoginDesktop from './Desktop'
import useCustom from './hooks'
import LoginMobile from './Mobile'

const Login = () => {
  const matches = useMediaQuery(MOBILE.MAX_WIDTH)
  const screenType = matches ? SCREEN_TYPE.DESKTOP : SCREEN_TYPE.MOBILE
  const { data } = useCustom({ screen: screenType })

  return matches ? (
    <LoginMobile ssoLinks={data.ssoLinks} />
  ) : (
    <LoginDesktop ssoLinks={data.ssoLinks} />
  )
}

export default Login
