import React from 'react'

import { Box } from '@material-ui/core'
import { Text } from '@xti/frontend-kit-components'

const NotDisplayWell = () => (
  <Box px={7} py={4}>
    <Text component="div" size="sm" variant="paragraph">
      Page may not display well on mobile devices.
    </Text>
    <Text component="div" size="sm" variant="paragraph">
      Please use a minimum of 1280x800 screen resolution for a better user
      experience.
    </Text>
  </Box>
)

export default NotDisplayWell
