import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Box } from '@material-ui/core'
import { NotAuthorized, NotFound } from '@xti/frontend-kit-components'
import classNames from 'classnames'

import DrawerMenu from '~/components/DrawerMenu'
import { ROLE_KEYS } from '~/config/constants'
import ChooseDashboardPage from '~/pages/ChooseDashboard'
import routes from '~/routes'

import useStyle from './style'

const AdminLayout = ({ isLandingPage, orgID, roleType }) => {
  const classes = useStyle()
  const mapRouteByRole = {
    [ROLE_KEYS.CLIENT_ADMIN]: 'clientAdminAccess',
    [ROLE_KEYS.SYS_ADMIN]: 'sysAdminAccess',
  }

  const routeDatas = routes.filter(
    (route) => !route.disabled && route?.[mapRouteByRole[roleType]],
  )

  return (
    <Box className={classes.container} display="flex" height="100%" flex={1}>
      {!isLandingPage && <DrawerMenu />}
      <Box
        className={classNames(classes.content, {
          [classes.contentShift]: true,
        })}
        display="flex"
        flexDirection="column"
        height="100%"
        flex={1}
        p={3}
      >
        <Box
          className={classes.contentHeader}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        />
        <Switch>
          {routeDatas.map(({ path, exact, component: Component }) => (
            <Route
              key={path}
              path={path}
              component={() => <Component drawerOpen />}
              exact={!!exact}
            />
          ))}
          <Route exact path="/" component={ChooseDashboardPage} />
          <Route exact path="/administrator">
            {roleType !== ROLE_KEYS.CLIENT_ADMIN ? (
              <Redirect to="/organization/list" />
            ) : (
              <Redirect to="/role/list" />
            )}
          </Route>
          <Route exact path="/organization">
            {roleType !== ROLE_KEYS.CLIENT_ADMIN ? (
              <Redirect to="/organization/list" />
            ) : (
              <Redirect to={`/organization/detail?orgId=${orgID}`} />
            )}
          </Route>
          <Route exact path="/group">
            <Redirect to="/group/list" />
          </Route>
          <Route exact path="/role">
            <Redirect to="/role/list" />
          </Route>
          <Route exact path="/user">
            <Redirect to="/user/list" />
          </Route>
          <Route exact path="/policy">
            <Redirect to="/policy/list" />
          </Route>
          <Route exact path="/app-settings/notifications">
            <Redirect to="/app-settings/notifications/list" />
          </Route>
          <Route exact path="/app-settings/recipient">
            <Redirect to="/app-settings/recipient/list" />
          </Route>
          <Route exact path="/log/notification">
            <Redirect to="/log/notification/list" />
          </Route>
          <Route exact path="/settings">
            <Redirect to="/settings/profile" />
          </Route>
          <Route exact path="/notifications">
            <Redirect to="/notifications/notification-center" />
          </Route>
          <Route exact path="/not-authorized" component={NotAuthorized} />
          <Route component={NotFound} />
        </Switch>
      </Box>
    </Box>
  )
}

AdminLayout.defaultProps = {
  isLandingPage: false,
  orgID: '',
  roleType: '',
}

AdminLayout.propTypes = {
  isLandingPage: PropTypes.bool,
  orgID: PropTypes.string,
  roleType: PropTypes.string,
}

export default AdminLayout
