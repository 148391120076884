/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import { ApolloProvider } from '@apollo/client'

import useAuth from '~/utils/auth'
import { createClient } from '~/utils/graphql'

const GraphqlProvider = ({ children }) => {
  const { isLoggedIn } = useAuth()
  const [client, setClient] = useState(createClient(false)[0])

  useEffect(() => {
    if (isLoggedIn) {
      const [apolloClient] = createClient(isLoggedIn)

      setClient(apolloClient)
    }
  }, [isLoggedIn])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GraphqlProvider
