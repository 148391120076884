import { SUBMENU_KEYS } from '~/config/constants'

export const MENU_NAME = {
  [SUBMENU_KEYS.SETTING_PROFILE]: {
    name: 'User Profile',
    closePath: '/',
    expandHeader: true,
  },
  [SUBMENU_KEYS.SETTING_PASSWORD]: {
    name: 'Change Password',
    closePath: '',
    expandHeader: false,
  },
  [SUBMENU_KEYS.SETTING_ADD_PHONE]: {
    name: 'Add Phone Number',
    closePath: '/settings/profile',
    expandHeader: false,
  },
  [SUBMENU_KEYS.NOTIFICATIONS_CENTER]: {
    name: 'Notifications',
    closePath: '',
    expandHeader: false,
  },
}

export default MENU_NAME
