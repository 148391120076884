import gql from 'graphql-tag'

const query = gql`
  query {
    auth {
      listSSOLink {
        links {
          name
          link
        }
      }
    }
  }
`

export default query
