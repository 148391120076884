import { useEffect, useState } from 'react'

import { useSubscription } from '@apollo/client'

import SUBSCRIPTION_LIST_OF_NOTIFICATION_QUERY from '~/queries/notification/subscribeList'
import useAuth from '~/utils/auth'

const useCustom = () => {
  const {
    totalUnread: totalUnreadQuery,
    fetchTotalUnreadNotificationData,
  } = useAuth()

  const [totalUnread, setTotalUnread] = useState(totalUnreadQuery)
  const [showPopover, setShowPopover] = useState(false)
  const [anchorEl, setAnchorEl] = useState()

  const { data: notifSubsData, loading: loadingNotifSubs } = useSubscription(
    SUBSCRIPTION_LIST_OF_NOTIFICATION_QUERY,
  )

  const handleOpen = (e) => {
    setShowPopover(true)
    setAnchorEl(e.currentTarget)
  }

  const handleChangeTotalUnread = (data) => {
    if (data || data === 0) {
      setTotalUnread(data)
    }
  }

  const handleClose = () => {
    setShowPopover(false)
  }

  useEffect(() => {
    fetchTotalUnreadNotificationData()
  }, [])

  useEffect(() => {
    if (totalUnreadQuery) {
      setTotalUnread(totalUnreadQuery)
    }
  }, [totalUnreadQuery])

  useEffect(() => {
    if (notifSubsData?.inapp?.getMessage?.pageInfo?.totalUnread) {
      setTotalUnread(notifSubsData?.inapp?.getMessage?.pageInfo?.totalUnread)
    }
  }, [notifSubsData])

  return {
    data: { totalUnread },
    handler: {
      handleChangeTotalUnread,
      handleClose,
      handleOpen,
    },
    state: {
      anchorEl,
      loadingNotifSubs,
      showPopover,
    },
  }
}

export default useCustom
