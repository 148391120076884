import { makeStyles } from '@material-ui/styles'
import { black } from '@xti/frontend-kit-components/colors'

const style = makeStyles((theme) => ({
  paper: {
    borderRadius: '10px',
    '&::after, &::before': {
      right: '100%',
      top: '50%',
      border: 'solid transparent',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
    },
    '&::after': {
      borderColor: 'transparent',
      borderRightColor: theme.palette.common.white,
      borderWidth: '30px',
      marginTop: '-30px',
    },
    '&::before': {
      borderColor: 'transparent',
      borderRightColor: black[200],
      borderWidth: '33px',
      marginTop: '-33px',
    },
  },
  icon: {
    marginRight: theme.spacing(2),
    width: '20px',
    height: '20px',
  },
}))

export default style
