import { makeStyles } from '@material-ui/styles'

const style = makeStyles((theme) => ({
  label: {
    color: ({ disabled }) => {
      if (disabled) return theme.palette.text.hint
      return theme.palette.text.primary
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
}))

export default style
