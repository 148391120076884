import React from 'react'

import { useMediaQuery } from '@material-ui/core'

import { MOBILE } from '~/config/screen'

import ForgetPasswordDesktop from './Desktop'
import ForgetPasswordMobile from './Mobile'

const ForgetPassword = () => {
  const matches = useMediaQuery(MOBILE.MAX_WIDTH)

  return matches ? <ForgetPasswordMobile /> : <ForgetPasswordDesktop />
}

export default ForgetPassword
