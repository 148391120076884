import * as Yup from 'yup'

const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .min(4, 'Email must contain at least 4 characters')
    .max(50, 'Email must be between 4 and 50 characters')
    .email('Invalid email')
    .required('Required'),
})

export default forgetPasswordSchema
