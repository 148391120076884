import {
  ROLE_KEYS,
  PRODUCT,
  PRODUCT_URL,
  PRODUCT_ALT,
} from '~/config/constants'

export const getProductData = (userProd = []) => {
  const res = []

  if (userProd.length > 0) {
    PRODUCT.forEach((data) => {
      const selectedProduct = userProd.find(
        (product) => product?.prodID === data,
      )
      if (selectedProduct) {
        res.push({
          productID: selectedProduct?.prodID,
          prodName: selectedProduct?.prodName,
          prodAltName: PRODUCT_ALT[selectedProduct?.prodID],
          prodImage: `${PRODUCT_ALT[selectedProduct?.prodID]}-logo.png`,
          prodPath: PRODUCT_URL[selectedProduct?.prodID],
        })
      }
    })
  }
  return res
}

export const getHelpfulLinkData = (roleType) => {
  let res = []
  const helpfulLinks = [
    {
      id: '1',
      name: 'Administration',
      alt: 'administration',
      img: 'administrator-logo.png',
      path: '/administrator',
      role: [ROLE_KEYS.CLIENT_ADMIN, ROLE_KEYS.SYS_ADMIN],
    },
    {
      id: '2',
      name: 'Audit Log',
      alt: 'audit-log',
      img: 'audit-log-logo.png',
      path: '/audit-log',
      role: [ROLE_KEYS.CLIENT_ADMIN],
    },
    {
      id: '3',
      name: 'Account Settings',
      alt: 'account-settings',
      img: 'account-settings-logo.png',
      path: '/settings/profile',
      role: [ROLE_KEYS.CLIENT_ADMIN, ROLE_KEYS.SYS_ADMIN, ROLE_KEYS.USER],
    },
    // {
    //   id: '4',
    //   name: 'Support',
    //   alt: 'support',
    //   img: 'support-logo.png',
    //   path: '',
    //   role: [ROLE_KEYS.CLIENT_ADMIN, ROLE_KEYS.SYS_ADMIN, ROLE_KEYS.USER],
    // },
  ]
  if (roleType) {
    res = helpfulLinks.filter((data) => data.role.includes(roleType))
  }
  return res
}

export default [getProductData, getHelpfulLinkData]
