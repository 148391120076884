import { lazy } from 'react'

import { DRAWER_MENU_CATEGORIES } from '~/config/constants'

const NotificationCenter = lazy(() => import('~/pages/Notification/Center'))
const NotificationSetting = lazy(() => import('~/pages/Notification/Settings'))

export default [
  {
    id: 'notification-center',
    parentId: 'notifications',
    title: 'Notification Center Page',
    path: '/notifications/notification-center',
    component: NotificationCenter,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    category: DRAWER_MENU_CATEGORIES.NOTIFICATIONS,
  },
  {
    id: 'notification-settings',
    parentId: 'notifications',
    title: 'Notification Settings Page',
    path: '/notifications/notification-settings',
    component: NotificationSetting,
    exact: true,
    disabled: false,
    shownOnMenu: true,
    withHeader: true,
    category: DRAWER_MENU_CATEGORIES.NOTIFICATIONS,
    desktopOnly: true,
  },
]
